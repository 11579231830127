import React from 'react';

import './css/filter.css';

import arrowDown from '../../assets/images/common/arrow_down.svg';


class Filters extends React.Component {

	constructor(props)
	{
		super(props);

		this.state = {
			options: props.options,
			defaultSelected: props.defaultSelected,
			label: props.label,
			optionsDivState: 'close',
			actionHandler: props.actionHandler,
			isMonthYearFilter: props.isMonthYearFilter ? props.isMonthYearFilter : false
		}


		this.openOptions = this.openOptions.bind(this);
		this.selectOption = this.selectOption.bind(this);
	}

	openOptions()
	{
		this.setState((state) => {
		  return state.optionsDivState ===	 'close'?{optionsDivState: 'open'}:{optionsDivState: 'close'};
		});
	}

	selectOption(item)
	{
		this.setState((state) => {
		  return {optionsDivState: 'close','defaultSelected':item};
		});

		this.state.actionHandler(item);
	}

	componentDidUpdate(prevProps)
	{
		if(this.props.defaultSelected !== prevProps.defaultSelected)
		{
			this.setState({defaultSelected:this.props.defaultSelected});
		}
	}

	render()
	{
		return(
			<div className="filterContainer">
				<div className={((this.state.optionsDivState === "close"?("filterSelectBox"):"filterSelectBox open") + (this.state.isMonthYearFilter ? " smallFilter" : ""))} onClick={this.openOptions}>
					{this.state.label && <div className="label">{this.state.label}</div>}
					<div className="selected">{this.state.defaultSelected.value}</div>
					<div className="arrowdown"><img src={arrowDown} alt="arrowDown"/></div>
				</div>

				{
					this.state.optionsDivState === "close"?"":<div className={("filterSelectOptions" + (this.state.isMonthYearFilter ? " smallFilter" : ""))} >
					{this.state.options.map((item, key) =>
					    <div className="filterOptions" key={key} onClick={() => this.selectOption(item)}>
					    	{item.value}
					    </div>
					)}
				</div>
				}
			</div>
		);
	}
}

export default Filters;

import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Link as RouterLink } from "react-router-dom";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Icon from "@material-ui/core/Icon";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import DialogContent from "@material-ui/core/DialogContent";
import moment from "moment";

import MainComponent from "./../main/main-component.jsx";
import { get, patch } from "./../../helpers/axios";
import { endPoints } from "./../../constants/end-point-url";
import { store } from "./../../helpers/store";
import {
  benefit_status_constant,
  FilterOptions
} from "./../../constants/benefit-status-constant.js";

import "./benefit-details.css";

import backArrow from "../../assets/images/benefitDetails/back.svg";

import ImpairmentHistoryComponent from "../impairment-history/impair-history-component";

const DialogTitle = props => {
  const { children, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className="dialogTitle">
      <Typography variant="h6" className="dialogTitleText">
        {children}
      </Typography>
      {onClose ? (
        <IconButton aria-label="Close" className="closeBtn" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};

class BenefitDetailsComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      cybePolicyNumber: "",
      url: "",
      bussinessDetails: "",
      payout: "",
      status: "",
      remark: "",
      mga_name: "",
      mga_email: "",
      open: false,
      last_monitored_status: "",
      id: props.match.params.id,
      ifBottomReached: false,
      state_transitions: [],
      role: ""
    };

    this.windowScroll = this.windowScroll.bind(this);
    this.showStatusList = this.showStatusList.bind(this);
    this.changeStatus = this.changeStatus.bind(this);
    this.cancelStatusChange = this.cancelStatusChange.bind(this);
  }
  handleClickOpen = () => {
    this.setState({
      open: true
    });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  showStatusList = () => {
    this.setState({
      statusOptions: true
    });
  };

  changeStatus = value => {
    var this_ = this;

    this.setState(
      {
        status: value,
        statusOptions: false
      },
      function() {
        this_.changeStatusApi();
      }
    );
  };

  cancelStatusChange = () => {
    this.setState({
      statusOptions: false
    });
  };

  componentDidMount() {
    var this_ = this;

    get(endPoints.single_warranty + this.state.id, {})
      .then(function(response) {
        var data = response.data;

        var status = "";

        benefit_status_constant.map(function(item, key) {
          data.state_transitions.map(function(item_, key_) {
            if (item.key === data.state_transitions[key_].name) {
              data.state_transitions[key_].name = item.value;
            }
          });
        });

        this_.setState({
          cybePolicyNumber: data.definition.cyber_policy_number,
          bussinessDetails: data.definition.business_name,
          url: data.definition.urls[0],
          status: data.state_transitions[0].name,
          payout: data.terms.payout,
          mga_name: data.beneficiary.name,
          mga_email: data.beneficiary.email,
          last_monitored_status: data.last_monitored_event,
          state_transitions: data.state_transitions
        });
      })
      .catch(function(error) {
        console.log(error);
      });
  }

  componentDidUpdate() {
    var userDetails = store.getState().authentication.user;
    if (userDetails.role !== this.state.role) {
      this.setState({ role: userDetails.role });
    }
  }

  windowScroll(e) {
    var target = e.target;

    if (target.scrollTop + target.offsetHeight >= target.scrollHeight) {
      this.setState({ ifBottomReached: true });
    } else {
      this.setState({ ifBottomReached: false });
    }
  }

  changeStatusApi() {
    var state = "";
    var this_ = this;

    benefit_status_constant.map(function(value, index) {
      if (this_.state.status === value.value) {
        state = value.key;
      }
    });

    patch(endPoints.single_warranty + this.state.id, [
      {
        op: "add",
        path: "/state_transitions/0",
        value: { name: state }
      }
    ]).then(function() {
      console.log("here");
    });
  }

  render() {
    var history = [];
    var state_transitions_arr = [];
    this.state.state_transitions.map(function(value, index) {
      state_transitions_arr.push(value.name);
    });
    var statusTriggered;
    if (this.state.status === "Triggered") {
      statusTriggered = "PENDING SELF CERT.";
    } else {
      statusTriggered = this.state.status;
    }
    return (
      <Grid
        container
        component="main"
        id="benefit-details"
        onScroll={this.windowScroll}
      >
        <CssBaseline />
        <MainComponent activeTab="benefits" />
        <Grid item xs={false} sm={10} md={10} className="benefitDetailsRoot">
          <div className="benHeader">
            <div className="detailsHeader">
              <Grid container className="benContainer">
                <Grid item xs={12} sm={6} md={6} className="benInfoCol">
                  <RouterLink to={"/all-benefits"}>
                    <img
                      src={backArrow}
                      className="backDetImg"
                      alt="back"
                    ></img>
                  </RouterLink>
                  <h3>
                    <span className="benId">{this.state.id}:</span>
                    <a href={this.state.url} className="benUrl">
                      {this.state.url.replace("https://", "")}
                    </a>
                  </h3>
                </Grid>
                {/* <Grid item xs={12} sm={6} md={6} className="btnCol">
                  <Button
                    variant="outlined"
                    className="downloadBtn"
                    disableRipple
                  >
                    DOWNLOAD WARRANTY CONTRACT
                  </Button>
                </Grid> */}
              </Grid>
              <Grid container className="benHeaderCont">
                <Grid item xs={6} sm={3} md={2}>
                  <p className="benheaderText">Business Name</p>
                  <p className="benValText">{this.state.bussinessDetails}</p>
                </Grid>
                <Grid item xs={6} sm={3} md={2}>
                  <p className="benheaderText">Cyber Policy Number</p>
                  <p className="benValText">{this.state.cybePolicyNumber}</p>
                </Grid>
                <Grid item xs={6} sm={3} md={2}>
                  <p className="benheaderText">Payout</p>
                  <p className="benValText">${this.state.payout}</p>
                </Grid>
                <Grid item xs={6} sm={3} md={2}>
                  <p className="benheaderText">MGA Name</p>
                  <p className="benValText">{this.state.mga_name}</p>
                </Grid>
                <Grid item xs={6} sm={3} md={2}>
                  <p className="benheaderText">MGA Email</p>
                  <p className="benValText mgaEmail">{this.state.mga_email}</p>
                </Grid>
              </Grid>
              <Grid container className="benHeaderCont">
                {this.state.role === "distributor" ? (
                  <Grid item xs={6} sm={3} md={2}>
                    <p className="benheaderText curserPointer">
                      Status (
                      <Link
                        className="benheaderText benheaderTextViewHist"
                        onClick={this.handleClickOpen}
                      >
                        View History
                      </Link>
                      )
                    </p>
                    <p className="benValText">
                      <span
                        className={
                          "statusChip " + this.state.status.toLowerCase()
                        }
                      >
                        {statusTriggered}
                      </span>
                    </p>
                  </Grid>
                ) : (
                  <Grid item xs={6} sm={3} md={2}>
                    <p className="benheaderText">
                      Status (
                      <Link
                        className="benheaderText benheaderTextViewHist curserPointer"
                        onClick={this.showStatusList}
                      >
                        Click to change
                      </Link>
                      )
                    </p>

                    {this.state.statusOptions ? (
                      <div>
                        <p className="benValText curserPointer">
                          {state_transitions_arr.includes("Paid") ? (
                            <span></span>
                          ) : (
                            <span></span>
                          )}

                          {state_transitions_arr.includes("Triggered") &&
                          !state_transitions_arr.includes(
                            "Ready for Payout"
                          ) ? (
                            <span
                              className="statusChip ready"
                              onClick={() =>
                                this.changeStatus("Ready for Payout")
                              }
                            >
                              READY FOR PAYOUT
                            </span>
                          ) : (
                            ""
                          )}
                          {state_transitions_arr.includes("Ready for Payout") &&
                          state_transitions_arr.includes("Triggered") ? (
                            <span
                              className="statusChip marginStatus paid"
                              onClick={() => this.changeStatus("Paid")}
                            >
                              PAID
                            </span>
                          ) : (
                            ""
                          )}
                          <span
                            className="statusChip cancel"
                            onClick={() => this.cancelStatusChange()}
                          >
                            CANCEL
                          </span>
                        </p>
                      </div>
                    ) : (
                      <p className="benValText">
                        <span
                          className={
                            "statusChip " + this.state.status.toLowerCase()
                          }
                        >
                          {statusTriggered}
                        </span>
                      </p>
                    )}
                  </Grid>
                )}

                {/* <Grid item xs={6} sm={3} md={4}></Grid> */}
                <Grid item xs={6} sm={3} md={2}>
                  <p className="benheaderText">Last Monitored Status</p>
                  {this.state.last_monitored_status === null ? (
                    ""
                  ) : (
                    <div>
                      <p className="benValText">
                        <span>
                          <Icon
                            className={
                              this.state.last_monitored_status.exception !== ""
                                ? "circleIconImpaired"
                                : "circleIconOk"
                            }
                          >
                            fiber_manual_record
                          </Icon>
                        </span>
                        {this.state.last_monitored_status.exception !== ""
                          ? "Website Impaired"
                          : "Website OK"}
                      </p>
                    </div>
                  )}
                </Grid>
              </Grid>
            </div>
            <div className="impairmentHeader">
              <ImpairmentHistoryComponent
                id={this.state.id}
                reachedBottom={this.state.ifBottomReached}
              ></ImpairmentHistoryComponent>
            </div>
            <Dialog
              onClose={this.handleClose}
              aria-labelledby="customized-dialog-title"
              open={this.state.open}
            >
              <DialogTitle
                id="customized-dialog-title"
                onClose={this.handleClose}
              >
                Benefit Status History
              </DialogTitle>
              <DialogContent className="diaContentPaddbottom">
                <Typography gutterBottom className="dialogStartText">
                  Keep track of the benefit's status changes since the day of
                  activation
                </Typography>
                {this.state.state_transitions.map(function(value, index) {
                  return (
                    <React.Fragment key={value}>
                      {!history.includes(value.name)
                        ? (history.push(value.name),
                          (
                            <Grid container spacing={3} key={index}>
                              <Grid item xs={6} sm={6} md={6}>
                                <Typography className="bendateText">
                                  {moment(value.modified * 1000).format(
                                    "DD MMMM YYYY"
                                  )}
                                </Typography>
                              </Grid>
                              <Grid item xs={5} sm={6} md={6}>
                                <Typography className="benValText rightAlign">
                                  <span
                                    className={
                                      "statusChip " + value.name.toLowerCase()
                                    }
                                  >
                                    {value.name === "Triggered"
                                      ? "PENDING SELF CERT."
                                      : value.name}
                                  </span>
                                </Typography>
                              </Grid>
                            </Grid>
                          ))
                        : ""}
                    </React.Fragment>
                  );
                })}
              </DialogContent>
            </Dialog>
          </div>
        </Grid>
      </Grid>
    );
  }
}

export default BenefitDetailsComponent;

import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import fadeLogo from "../../assets/images/common/fade_logo.svg";
import "./pending-activation.css";

import TableHeaderComponent from "../tableHeader/table-header-component";
import MainComponent from "./../main/main-component.jsx";
import CollapsibleTableRowComponent from "./../collapsibleTableRow/collapsibleTableRow-component";

import { screenHeadings } from "./../../constants/table-heading-constants";
import { get, post } from "./../../helpers/axios";
import { endPoints } from "./../../constants/end-point-url";
import Loader from "../common/loader";

class PendingActivationComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      screenHeadings: screenHeadings.pendingActivation,
      filterBy: { key: "", value: "Show All" },
      filterOption: [
        { key: "", value: "Show All" },
        { key: 1, value: "Eligible" },
        { key: 0, value: "Ineligible" }
      ],
      sortBy_param: "business_name",
      sortDir: "desc",
      list: [],
      warranty: {},
      eligible: "",
      limit: 12,
      callcount: 0,
      ifBottomReached: false,
      scroll: false,
      has_more: true,
      sortData: false,
      listLoader: false,
      loading_data: false
    };

    this.filterHandler = this.filterHandler.bind(this);
    this.sortHandler = this.sortHandler.bind(this);
    this.searchHandler = this.searchHandler.bind(this);
    this.activateBenefit = this.activateBenefit.bind(this);
    this.getQuotes = this.getQuotes.bind(this);
    this.windowScroll = this.windowScroll.bind(this);
    this.clearSearchText = this.clearSearchText.bind(this);
  }

  componentDidMount() {
    this.setState({ listLoader: true });
    this.getQuotes({ limit: this.state.limit });
  }

  filterHandler(filterBy) {
    this.clearSearchText();
    var this_ = this;
    if (this.state.filterBy) {
      this_.setState({
        eligible: "",
        ifBottomReached: false,
        scroll: false
      });
    }
    this_.setState(
      {
        loading: true,
        callcount: 0,
        filterLoder: true,
        search: ""
      },
      function() {
        if (filterBy.key !== "") {
          this_.setState({
            limit: this.state.limit,
            filterBy: filterBy,
            eligible: filterBy.key,
            filter: true
          });
          this_.getQuotes({ eligible: filterBy.key });
        } else {
          this.setState({
            filterBy: { key: "", value: "Show All" },
            states: "Show All",
            eligible: "",
            filter: true
          });
          this_.getQuotes({ limit: this.state.limit });
        }
      }
    );
  }

  sortHandler(parameter, sortDir) {
    var this_ = this;

    if (this.state.eligible !== "") {
      this.setState(
        {
          sortBy_param: parameter,
          sortDir: sortDir,
          sortData: true
        },
        function() {
          this.getQuotes({
            limit: this.state.limit,
            sort_by: parameter,
            sort_dir: sortDir,
            eligible: this.state.eligible
          });
        }
      );
    } else {
      this.setState(
        {
          sortBy_param: parameter,
          sortDir: sortDir,
          sortData: true
        },
        function() {
          this.getQuotes({
            limit: this.state.limit,
            sort_by: parameter,
            sort_dir: sortDir
          });
        }
      );
    }
  }

  searchHandler(term) {
    this.setState(
      {
        search: term,
        ifBottomReached: false,
        loading: true
      },
      function() {
        this.getQuotes({ limit: this.state.limit, q: term });
      }
    );
  }
  clearSearchText() {
    this.setState({
      search: ""
    });
    this.refs.tableHeader.clearSearchText();
  }

  activateBenefit(quote) {
    this.setState({ Activate: true });
    var param = {
      terms: quote.offers[0],
      quote_id: quote.id
    };

    var this_ = this;

    post(endPoints.all_warranties, param).then(function(response) {
      this_.setState({ warranty: response.data });
    });
  }

  getQuotes(params) {
    if (this.state.loading_data === false) {
      var this_ = this;

      // params.limit = this.state.limit;
      var callcount = this.state.callcount;

      if (this.state.filter && this.state.ifBottomReached) {
        if (this.state.states === "Show All") {
          params.skip = this.state.limit * (callcount + 1);

          this.setState({
            callcount: callcount + 1,
            scroll: true
          });
        } else {
          params = { eligible: this.state.filterBy.key };
          params.skip = this.state.limit * (callcount + 1);

          this.setState({
            callcount: callcount + 1,
            scroll: true
          });
        }
      }

      if (this.state.sortData === true && this.state.ifBottomReached) {
        params = {
          sort_by: this.state.sortBy_param,
          sort_dir: this.state.sortDir
        };
        params.skip = this.state.limit * (callcount + 1);

        this.setState({
          callcount: callcount + 1,
          scroll: true
        });
      } else if (this.state.ifBottomReached) {
        params.skip = this.state.limit * (callcount + 1);

        this.setState({
          callcount: callcount + 1,
          scroll: true
        });
      }
      if (this.state.Activate !== true) {
        this_.setState({ loading_data: true }, function() {
          get(endPoints.pending_activation, {
            params: params
          }).then(function(response) {
            var list = this_.state.list;
            if (callcount !== this_.state.callcount) {
              response.data.data = list.concat(response.data.data);
              if (response.data.has_more === false) {
                this_.setState({ has_more: false });
              }
            }
            this_.setState({
              list: response.data.data,
              sortData: false,
              // filter: false,
              listLoader: false,
              loading: false,
              loading_data: false,
              filterLoader: false
            });
          });
        });
      }
    }
  }
  windowScroll(e) {
    var target = e.target;

    var this_ = this;
    if (target.scrollTop + target.offsetHeight >= target.scrollHeight) {
      this.setState({ ifBottomReached: true, scroll: true }, function() {
        this_.getQuotes({});
      });
    } else {
      this.setState({ ifBottomReached: false, scroll: false }, function() {});
    }
  }
  render() {
    return (
      <Grid container component="main" className="root">
        <CssBaseline />
        <MainComponent activeTab="pending-activation" />
        <Grid
          item
          xs={false}
          sm={10}
          md={10}
          className="pending-activation-container"
          onScroll={this.windowScroll}
        >
          <TableHeaderComponent
            screenHeadings={this.state.screenHeadings}
            searchHandler={this.searchHandler}
            sortHandler={this.sortHandler}
            filterHandler={this.filterHandler}
            sortDir={this.state.sortDir}
            sortBy_param={this.state.sortBy_param}
            filterOptions={this.state.filterOption}
            defaultFilter={this.state.filterBy}
            type={this.state.screenHeadings.type}
            ref="tableHeader"
          />
          {this.state.loading === true ||
          this.state.filterLoader === true ||
          this.state.sortData === true ||
          this.state.listLoader === true ? (
            <Loader
              height="34px"
              width="34px"
              background="#EFF3F7"
              marginTop="40px"
            />
          ) : (
            ""
          )}
          {!!this.state.list.length ? (
            <Grid
              item
              xs={false}
              sm={12}
              md={12}
              className="tableRowsContainer"
            >
              {this.state.list.map((item, key) => (
                <CollapsibleTableRowComponent
                  key={key}
                  item={item}
                  type={this.state.screenHeadings.type}
                  activateBenefit={this.activateBenefit}
                  warranty={this.state.warranty}
                  refreshList={this.getQuotes}
                />
              ))}
              {this.state.loading_data === true &&
              this.state.has_more === true ? (
                <Loader
                  height="34px"
                  width="34px"
                  background="#EFF3F7"
                  marginTop="40px"
                />
              ) : (
                ""
              )}
            </Grid>
          ) : (
            <div className="tableRowsContainer">
              <div className="noDataMessage">
                <img src={fadeLogo} alt="logo" className="noDataImg"></img>
                <p className="noDataMessageLbl">No results found.</p>
              </div>
            </div>
          )}
        </Grid>
      </Grid>
    );
  }
}

export default PendingActivationComponent;

import { endPoints } from "../constants/end-point-url";
import { login, get } from "../helpers/axios";
import { userActions } from "./../actions/user-action";
import { store } from "./../helpers/store";
import { history } from "./../helpers/history";

export const userService = {
  loginUser,
  logout,
  getUser
};

function loginUser(username, password, cb) {
  var body = {
    email: username,
    password: password
  };

  return login(endPoints.login, body)
    .then(function(response) {
      localStorage.setItem("token", response.data.token);
      cb(null);
      getUser().finally(function() {
        history.push("all-benefits");
      });
    })
    .catch(function(error) {
      cb(error);
      store.dispatch(userActions.failure(error));
    });
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem("token");
  store.dispatch(userActions.logout());
}

function getUser() {
  return get(endPoints.user, {}).then(function(response) {
    store.dispatch(userActions.success(response.data));
  }).catch(function(error){
    console.log(error);
  });;
}

import React, { Component } from "react";
import loader from "../../assets/images/loader/loader_64.gif";
class Loader extends Component {
  render() {
    return (
      <div
        align="center"
        className="loader_div"
        style={{
          backgroundColor: this.props.background
        }}
      >
        <img
          src={loader}
          alt="loading..."
          height={this.props.height}
          width={this.props.width}
          style={{ marginTop: this.props.marginTop }}
        />
      </div>
    );
  }
}

export default Loader;

import React from "react";
import { Router, Route, Switch } from "react-router-dom";
import { history } from "./helpers/history";
import BenefitsComponent from "./components/benefits/benefits-component";
import LoginComponent from "./components/login/login-component";
import ContactInfoComponent from "./components/contactInfo/contactInfo-component";
import BenefitDetailsComponent from "./components/benefit-details/benefit-details-component";
import PendingActivationComponent from "./components/pending-activation/pending-activation-component";
import RawDataComponent from "./components/raw-data/raw-data-component";
import { userService } from "./services/login-service";
import PrivateRoute from "./components/common/privateRoutes";

class App extends React.Component {
  constructor(props) {
    super(props);

    //const { dispatch } = this.props;
    history.listen((location, action) => {
      // clear alert on location change
      // dispatch(alertActions.clear());
    });

    if (localStorage.getItem("token")) {
      userService.getUser();
    }
  }
  render() {
    return (
      <Router history={history}>
        <div className="App">
          <Route exact path="/" render={() => <LoginComponent />} />
          <div className="container">
            {
              <Switch>
                <Route exact path="/login" render={() => <LoginComponent />} />
                <Route
                  exact
                  path="/contact"
                  render={() => <ContactInfoComponent />}
                />
                <PrivateRoute
                  exact
                  path="/benefit-details/:id"
                  component={BenefitDetailsComponent}
                />
                <PrivateRoute
                  exact
                  path="/all-benefits"
                  component={BenefitsComponent}
                />
                <PrivateRoute
                  exact
                  path="/pending-activation"
                  component={PendingActivationComponent}
                />
                <PrivateRoute
                  exact
                  path="/raw-data/:id/:start?/:end?"
                  component={RawDataComponent}
                />
              </Switch>
            }
          </div>
        </div>
      </Router>
    );
  }
}

export default App;

export function benefitsDetails(state = {}, action){

	switch(action.type)
	{
		case 'STORE_BENEFIT_DETAILS': {
			return Object.assign({}, state, {"details":action.details});
		}
		default:{
			return state;
		}
	}
} 
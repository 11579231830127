export const screenHeadings = {
	distributor: {
		mainHeading: "Activated Benefits",
		subHeading: "Manage benefits you have issued",
		"searchPlaceHolder" : "Search for ID, URL or Business Na..",
		"tableHeadings" : [
			{key: "definition.business_name", "value": "Business Name"},
			{key: "definition.urls", value: "Website URL"}, 
			{key: "definition.cyber_policy_number", value: "Cyber Policy Number"},
			{key: "id", value: "Benefit ID"}, 
			{key: "", value: "Benefit Status"}
		],
		type: "activated_benefits"
	},

	admin: {
		mainHeading: "All Benefits",
		subHeading: "Manage the various benefits issued by the Managing General Agent (MGA) registered with you",
		"searchPlaceHolder" : "Search for MGA name or email…",
		"tableHeadings" : [
			{key: "definition.business_name", "value": "Business Name"},
			{key: "definition.urls", value: "Website URL"}, 
			{key: "owner", value: "MGA Name"}, 
			{key: "definition.cyber_policy_number", value: "Cyber Policy Number"},
			{key: "", value: "Benefit Status"}
		],
		type: "activated_benefits_admin"
	},
	pendingActivation: {
		mainHeading: "Non Activated",
		subHeading: "Create and activate benefits for relevant customers ",
		"searchPlaceHolder" : "Search for ID, URL or Business Name…",
		"tableHeadings" : [
			{key: "definition.business_name", "value": "Business Name"},
			{key: "definition.urls", value: "Website URL"}, 
			{key: "definition.cyber_policy_number", value: "Cyber Policy Number"},
			{key: "", value: ""},
			{key: "eligible", value: "Eligibility Status"}
		],
		type: "pending_activation"
	}	
}
import React from 'react';
import Grid from '@material-ui/core/Grid';
import Collapse from '@material-ui/core/Collapse';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import Icon from '@material-ui/core/Icon';


//css
import './collapsibleTableRow-component.css';
import './../common/css/common.css';

//assets
import panelOpen from './../../assets/images/common/arrow_down.svg';
import panelClose from './../../assets/images/common/arrow_up.svg';
import tickIcon from './../../assets/images/common/tick.svg'



class CollapsibleTableRowComponent extends React.Component{
	constructor(props)
	{
		super(props);

		if(props.type !== "pending_activation")
		{
			this.state = {
				pane_open: false,
				dialogOpen: false,
				confirmOpen: false,
				item: props.item,
				url: props.url,
				state_transitions: props.item.state_transitions[0],
				status: props.status,
				type: props.type,
				warranty: {}
			};

		}
		else
		{
			this.state = {
				pane_open: false,
				dialogOpen: false,
				confirmOpen: false,
				item: props.item,
				type: props.type,
				activateBenefit: props.activateBenefit,
				warranty: props.warranty,
				refreshList: props.refreshList,

			};
		}

		this.collapsePane = this.collapsePane.bind(this);
		this.handleConfirmToggle = this.handleConfirmToggle.bind(this);
		this.handleConfirmToggleClose=this.handleConfirmToggleClose.bind(this)
		this.handleDialogToggle = this.handleDialogToggle.bind(this);
		this.refreshList = this.refreshList.bind(this);

		this.viewUrl = '/benefit-details/'+this.state.item.id;
	}

	componentDidUpdate(prevProps)
	{


		if(prevProps.type !== this.props.type)
		{
			this.setState({
				type:this.props.type,

			});
		}

		if(prevProps.warranty !== this.props.warranty)
		{
			this.setState({
				warranty:this.props.warranty,
			});
		}

		if(prevProps.item !== this.props.item)
		{
			var this_ = this;
			this.setState({
				item: this.props.item,
				url: this.props.url,
				state_transitions: (this.props.type !== "pending_activation")?this.props.item.state_transitions[0]:"",
			},function(){
				this_.viewUrl = '/benefit-details/'+this.state.item.id;
			});


		}

	}

	collapsePane()
	{
		this.setState({pane_open: !this.state.pane_open});
	}
	handleConfirmToggleClose() {
		
		var this_ = this;
		
		this.setState({confirmOpen: !this.state.confirmOpen},function(){
			if(!this_.state.confirmOpen)
			{
				window.location.reload();
			}
		});
		
	
	}

	handleConfirmToggle() {
		
		var this_ = this;
		this.setState({dialogOpen: false});
		this.setState({confirmOpen: !this.state.confirmOpen},function(){
			if(!this_.state.confirmOpen)
			{
				this_.refreshList();
			}
		});
		this.state.activateBenefit(this.state.item)
	
	}

	handleDialogToggle() {
		
		this.setState({dialogOpen: !this.state.dialogOpen});
	}

	refreshList()
	{
		this.state.refreshList();
	}

	render()
	{

		return(
			<div>
			<Grid container className="tablerow" onClick={this.collapsePane}>
		    	<Grid item xs={12} sm={12} md={12} >
		    		<Grid container className="main-table">
						{ this.state.type !== 'pending_activation'
							? <Grid item xs={1} sm={1} md={1} className="collapseIcon">
							<img src={this.state.pane_open?panelClose:panelOpen} alt=""/>
							</Grid>
							: (<Grid item xs={1} sm={1} md={1}>
							</Grid>)
						}
		           		<Grid item xs={2} sm={2} md={2} id="business-name">{this.state.item.definition.business_name}</Grid>
						   {this.state.type !== 'pending_activation'
							?(<Grid item xs={2} sm={2} md={2} className="primarytext underline" id="website_url">{this.state.item.definition.urls[0].replace("https://","").replace("http://","").substring(0,30)}</Grid>)
							:(<Grid item xs={2} sm={2} md={3} className="primarytext underline pending_data" >{this.state.item.definition.urls[0].replace("https://","").replace("http://","").substring(0,30)}</Grid>)}
		           		

							{this.state.type !== 'pending_activation'
							?(<Grid item xs={2} sm={2} md={2} className="secondarytext" id="MGM-name" >{this.state.type !== "activated_benefits_admin"?this.state.item.definition.cyber_policy_number:this.state.item.owner.name}</Grid>)
							:(<Grid item xs={2} sm={2} md={2} className="secondarytext pending_data_policy_number"  >{this.state.type !== "activated_benefits_admin"?this.state.item.definition.cyber_policy_number:this.state.item.owner.name}</Grid>)}
		           		




		           		{
		           			this.state.type !== "pending_activation"?
		           			<Grid item xs={2} sm={2} md={2} className="secondarytext"  id="cyber_policy_number">
								{this.state.type !== "activated_benefits_admin"?this.state.item.id:this.state.item.definition.cyber_policy_number}
							</Grid>:""
		           		}

					   { this.state.type !== 'pending_activation' && this.state.state_transitions !== undefined
							? this.state.status.map((item,key)=>
						
 									item.key === this.state.state_transitions.name?
									<Grid item xs={2} sm={2} md={2} key={key} ><div className="benValText text-right"><span className={"statusChip "+item.value}>{(	item.value==="Triggered"? "PENDING SELF CERT.":item.value).toUpperCase()}</span></div></Grid>
									:
									"")

							: this.state.item.eligible
								? <Grid item xs={2} sm={2} md={2} className="text-right"> <span className="activateButtons" onClick={this.handleDialogToggle} disableRipple> ACTIVATE  </span> </Grid>
								: <Grid item xs={2} sm={2} md={2} className="text-right">   <span className='inelligibleChip'> INELIGIBLE </span> </Grid>

						}
		           		<Grid item xs={1} sm={1} md={1}></Grid>
		    		</Grid>
		    	</Grid>

			    <Grid item xs={12} sm={12} md={12} >
				{ this.state.type !== 'pending_activation'
							? <Collapse in={this.state.pane_open}>
							<div className="expandedRow">
							<Grid container className="expandedDetailsContainer">

								<Grid item xs={2} sm={2} md={2} >

								<div className="tableHeading-collapse">
									Payout Amount
								</div>
								<div>
									${this.state.item.terms.payout}
								</div>


								</Grid>

									<Grid item xs={3} sm={3} md={3} >
										{
											this.state.item.last_monitored_event === null  ? "" :
											<div className="tableHeading-container">
												<div className="tableHeading-collapse">
													Current Status
												</div>
												<div>

												</div>
												<div>
													<span>
				                                      <Icon className = {this.state.item.last_monitored_event.exception !== ""?"circleIconImpaired":"circleIconOk"}>fiber_manual_record</Icon>
				                                  </span>
				                                  {this.state.item.last_monitored_event.exception !== ""?"Website Impaired":"Website OK"}
												</div>
											</div>
										}
									</Grid>

								<Grid item xs={7} sm={7} md={7} className="text-right">

									<div className="viewDetailsButtonContainer">
										<Link to={this.viewUrl}>
											<Button variant="outlined" color="primary" className="viewButton" disableRipple>
												<span className="button_label">View Details</span>
											</Button>
										</Link>
									</div>
								</Grid>

							</Grid>
							</div>
						</Collapse>
							: <div></div>
						}
       			</Grid>
			</Grid>
			<div>
			<Dialog open={this.state.dialogOpen}
					onClose={this.handleDialogToggle}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description">
					<DialogTitle id="alert-dialog-title">
						<Typography className='noBottomMargin'>Activate Benefit</Typography>
         				 <IconButton aria-label="Close" className='closeBtn' onClick={this.handleDialogToggle}>
            				<CloseIcon />
          				</IconButton>
					</DialogTitle>
					<DialogContent>
						<DialogContentText id="alert-dialog-description" className="actiBenDialog">
						Are you sure you want to activate benefit for
							<a href = 'https://shiftly.finance.com' className = "actiHeavyBenDialog linkName"> {this.state.item.definition.urls[0]}
							</a> ?
         				</DialogContentText>
						 <DialogContentText id="alert-dialog-description" className = "actiHeavyBenDialog">
						NOTE: This action cannot be undone
         				</DialogContentText>
					</DialogContent>
					<DialogActions className="actionSec">
						<Button onClick={this.handleDialogToggle} disableRipple className = "cancelBtn">
							CANCEL
          				</Button>
						<Button onClick={this.handleConfirmToggle} disableRipple className = "activateBtn" >
							ACTIVATE
          			</Button>
					</DialogActions>
				</Dialog>
			</div>
			<div>
			<Dialog open={this.state.confirmOpen}
				onClose={this.handleConfirmToggle}activateBtn
				aria-labelledby="alert-dialog-titlactivateBtn
				aria-describedby="alert-dialog-desactivateBtn
				className = "confirmDialog" >
                 
                    {
                    	this.state.warranty.hasOwnProperty('definition') ?   
						<React.Fragment>
						<DialogTitle id="alert-dialog-title">
						<Typography className="benefitActivatedDialog">Benefit Activated </Typography>
         				 <IconButton aria-label="Close" className='closeBtn' onClick={this.handleConfirmToggleClose}>
            				<CloseIcon />
          				</IconButton>
					</DialogTitle>
					<DialogContent className = "diaContentPaddbottom">
                        <Grid container spacing={3}>
                            <Grid item xs={6} sm={6} md={6}>
                                <p className = "bendateText">Business Name
                                </p>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} >
                                <p className="benValText rightAlign">
                                   {this.state.warranty.definition.business_name}
                                </p>
                            </Grid>
                            </Grid>
                        <Grid container spacing={3}>
                            <Grid item xs={6} sm={6} md={6}>
                                <p className = "bendateText">Business Email
                                </p>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} >
                                <a className="benValText floatRight primarytext underline" href = "management@shiftly.com">
									{this.state.warranty.beneficiary.email}
                                </a>
                            </Grid>
                            </Grid>
                        <Grid container spacing={3}>
                            <Grid item xs={6} sm={6} md={6}>
                                <p className = "bendateText"> Business Phone No.
                                </p>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} >
                                <p className="benValText rightAlign">
									{this.state.warranty.beneficiary.phone}
                                </p>
                            </Grid>
                        </Grid>
						{
							this.state.warranty.definition.urls.map(function(item,key){
								return <Grid container spacing={3} key={key}>
		                            <Grid item xs={6} sm={6} md={6}>
		                                <p className = "bendateText"> Website URL
		                                </p>
		                            </Grid>
		                            <Grid item xs={6} sm={6} md={6}  >
		                                <a  className="benValText floatRight primarytext underline"   href = "https://shiftly.finance">
											{item}
		                                </a>
		                            </Grid>
		                        </Grid>
							})
						}
                        <Grid container spacing={3}>
                            <Grid item xs={6} sm={6} md={6}>
                                <p className = "bendateText">Cyber Number Policy
                                </p>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} >
                                <p className="benValText rightAlign" >
									{this.state.warranty.definition.cyber_policy_number}
                                </p>
                            </Grid>
                            </Grid>
                        <Grid container spacing={3}>
                            <Grid item xs={6} sm={6} md={6}>
                                <p className = "bendateText">
									Benefit ID
                                </p>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} >
                                <p className="benValText rightAlign">
									{this.state.warranty.id}
                                </p>
                            </Grid>
                            </Grid>

                        <Grid container spacing={3}>
                            <Grid item xs={6} sm={6} md={6}>
                                <p className = "bendateText">
									Payout
                                </p>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} >
                                <p className="benValText rightAlign">
									${this.state.warranty.terms.payout}
                                </p>
                            </Grid>
                            </Grid>

                        <Grid container spacing={3}>
                            <Grid item xs={4} sm={4} md={4}>
                            </Grid>
                            <Grid item xs={8} sm={8} md={8} className="activationCol" >
                                <p className="activationText">
								<img src={tickIcon} alt="" className="tickIcon" />
									BENEFIT ACTIVATED SUCCESSFULLY
                                </p>
                            </Grid>
                            </Grid>
							<Grid container spacing={3}>
							<Grid item xs={4} sm={4} md={4}>
                            </Grid>
                            <Grid item xs={8} sm={8} md={8} className = "activationNotesCol">
								<p className = "activationNotes">
								You can check this activated benefit in the 'Activated Benfits' section
								</p>
                            </Grid>
                            </Grid>
                    </DialogContent>
					</React.Fragment>: ""
                    }
                </Dialog>
			</div>
		</div>
		);
	}
}

export default CollapsibleTableRowComponent;

var axios = require("axios");

function authHeader() {
  // return authorization header with jwt token
  let token = localStorage.getItem("token");

  if (token) {
    return { Authorization: "Bearer " + token };
  } else {
    return {};
  }
}

export function get(url, params) {
  params.headers = authHeader();

  return axios.get(url, params).catch(function(error) {
    //Session Timeout hanlder
    if (
      error.response &&
      error.response.status &&
      error.response.status == "401"
    ) {
      localStorage.removeItem("token");
      window.location.reload();
    }
    throw error;
  });
}

export function post(url, params) {
  return axios
    .post(url, params, {
      headers: authHeader()
    })
    .catch(function(error) {
      //Session Timeout hanlder
      if (
        error.response &&
        error.response.status &&
        error.response.status == "401"
      ) {
        localStorage.removeItem("token");
        window.location.reload();
      }
      throw error;
    });
}

export function patch(url, params) {
  return axios
    .patch(
      url,
      {
        patches: params
      },
      {
        headers: authHeader()
      }
    )
    .catch(function(error) {
      //Session Timeout hanlder
      if (
        error.response &&
        error.response.status &&
        error.response.status == "401"
      ) {
        localStorage.removeItem("token");
        window.location.reload();
      }
      throw error;
    });
}

export function login(url, params) {
  return axios.post(url, params);
}

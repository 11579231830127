var url = "https://api.platform.machinecover.com/v1";

export const endPoints = {
  all_warranties: url + "/contracts",
  single_warranty: url + "/contracts/",
  login: url + "/login",
  auth_url: "/all-benefits",
  user: url + "/account",
  pending_activation: url + "/quotes",
  events: url + "/contracts/:id/events",
  scans: url + "/contracts/:id/scans"
};

export const warrantiesParamKeys = {
  benefit_id: "id",
  url: "url",
  business_name: "business_name",
  policy_number: "cyber_policy_number",
  state: "state",
  owner: "owner"
};

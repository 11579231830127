import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";

import Filters from "./../common/filters.jsx";
import downlaodImg from "../../assets/images/rawData/download_blue.svg";
//assets
import ascending from "./../../assets/images/common/ascending.svg";
import descending from "./../../assets/images/common/descending.svg";
import noSort from "./../../assets/images/common/no_sort.svg";
import searchGlass from "./../../assets/images/common/search.svg";
import { Button } from "@material-ui/core";
import "./table-header.css";
import { type } from "os";

class TableHeaderComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sortDir: props.sortDir,
      screenHeadings: props.screenHeadings,
      searchHandler: props.searchHandler,
      sortHandler: props.sortHandler,
      filterHandler: props.filterHandler,
      sortBy_param: props.sortBy_param,
      searchText: "",
      filterOptions: props.filterOptions,
      defaultFilter: props.defaultFilter,
      type: props.type,
      previousSearchTimeoutRef: null
    };

    this.sortList = this.sortList.bind(this);
    this.search = this.search.bind(this);
    this.clearSearchText = this.clearSearchText.bind(this);
    this.filterList = this.filterList.bind(this);
  }

  static getDerivedStateFromProps(nextProps) {
    return {
      sortDir: nextProps.sortDir,
      sortBy_param: nextProps.sortBy_param,
      defaultFilter: nextProps.defaultFilter,
      screenHeadings: nextProps.screenHeadings
    };
  }

  search(e) {
    var this_ = this;

    if (this_.state.previousSearchTimeoutRef) {
       clearTimeout(this_.state.previousSearchTimeoutRef);
    }

    this_.setState({
       searchText: e.target.value.trim(),
       previousSearchTimeoutRef: setTimeout(function () {
            this_.state.searchHandler(this_.state.searchText);
         }, 500)
    });
  }

  clearSearchText(){
    this.setState(
      {
        searchText: ""
      }
    );
  }
  sortList(parameter) {
    var sortDir = this.state.sortDir === "desc" ? "asc" : "desc";
    this.state.sortHandler(parameter, sortDir);
  }

  filterList(item) {
    this.state.filterHandler(item);
  }

  render() {
    return (
      <div className="benefits_main">
        <Grid item xs={12} sm={12} md={12}>
          <Grid item xs={12} sm={12} md={12}>
            <Typography variant="h3" gutterBottom className="mainHeading">
              {this.state.screenHeadings.mainHeading}
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              className="secondarytext subHeading"
            >
              {this.state.screenHeadings.subHeading}
            </Typography>
          </Grid>
          {/* <Grid item xs={6} sm={6} md={6} className="download-btn">
            <img src={downlaodImg} alt="download" className="downloadImg" />

            <Button
              className="primarytext"
              disableRipple
              // onClick={() =>
              //   this.export_table_to_csv(this.csv_data, "Raw_data.csv")
              // }
            >
              Download as PDF
            </Button>
          </Grid> */}
          <Grid item xs={12} sm={12} md={12}>
            <Grid container>
              <Grid item xs={12} sm={8} md={8}>
                <div className="textboxContainer">
                  <TextField
                    className="inputbox"
                    margin="normal"
                    variant="outlined"
                    placeholder={this.state.screenHeadings.searchPlaceHolder}
                    fullWidth
                    value={this.state.searchText}
                    onChange={this.search}
                  />
                  <img
                    src={searchGlass}
                    className="searchGlass"
                    alt="searchGlass"
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <div className="margin-filter-container">
                  <Filters
                    defaultSelected={this.state.defaultFilter}
                    options={this.state.filterOptions}
                    label="Filter By:"
                    actionHandler={this.filterList}
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>
          {this.state.type !== "pending_activation" ? (
            <Grid item xs={12} sm={12} md={12}>
              <Grid container className="tableTop">
                {this.state.screenHeadings.tableHeadings.map((item, key, arr) =>
                  item.key !== "" ? (
                    <Grid
                      item
                      xs={2}
                      sm={2}
                      md={2}
                      className={
                        key !== arr.length - 1
                          ? "tableHeading"
                          : "tableHeading text-right last-heading"
                      }
                      key={key}
                      onClick={() => this.sortList(item.key)}
                    >
                      {item.value}
                      <img
                        src={
                          this.state.sortBy_param === item.key
                            ? this.state.sortDir === "desc"
                              ? descending
                              : ascending
                            : noSort
                        }
                        alt="sort"
                      />
                    </Grid>
                  ) : (
                    <Grid
                      item
                      xs={2}
                      sm={2}
                      md={2}
                      className={
                        key !== arr.length - 1
                          ? "tableHeading"
                          : "tableHeading text-right last-heading"
                      }
                      key={key}
                    >
                      {item.value}
                    </Grid>
                  )
                )}
              </Grid>
            </Grid>
          ) : (
            <Grid item xs={12} sm={12} md={12}>
              <Grid container className="tableTop">
                {this.state.screenHeadings.tableHeadings.map((item, key, arr) =>
                  item.key !== "" ? (
                    <Grid
                      item
                      xs={2}
                      sm={2}
                      md={2}
                      className={
                        key !== arr.length - 1
                          ? "tableHeading_pending"
                          : "tableHeading_pending_last text-right last-heading_pending"
                      }
                      key={key}
                      onClick={() => this.sortList(item.key)}
                    >
                      {item.value}
                      <img
                        src={
                          this.state.sortBy_param === item.key
                            ? this.state.sortDir === "desc"
                              ? descending
                              : ascending
                            : noSort
                        }
                        alt="sort"
                        className="sortIcon"
                      />
                    </Grid>
                  ) : (
                    ""
                  )
                )}
              </Grid>
            </Grid>
          )}
        </Grid>
      </div>
    );
  }
}

export default TableHeaderComponent;

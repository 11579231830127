import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";

import { store } from "./../../helpers/store";
import Loader from "../common/loader";
import { userService } from "./../../services/login-service";

//css
import "./main-component.css";
import "./../common/css/common.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

//assets
import logo from "./../../assets/images/mainComponent/wid_logo.png";
import benifitSheild from "./../../assets/images/mainComponent/benefits.svg";
import blueBenefitSheild from "./../../assets/images/mainComponent/benefits_selected.svg";
import account from "./../../assets/images/mainComponent/settings.svg";
import powerebBy from "./../../assets/images/mainComponent/powered_by.svg";

class MainComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      email: "",
      details: "",
      role: "",
      activeTab: props.activeTab
    };
  }

  componentDidUpdate() {
    var userDetails = store.getState().authentication.user;
    if (
      userDetails &&
      this.state.name !== userDetails.name &&
      userDetails.role === "distributor"
    ) {
      this.setState({
        name: userDetails.name,
        email: userDetails.email,
        details: userDetails.products[0].metadata,
        role: userDetails.role
      });
    } else if (
      userDetails &&
      this.state.name !== userDetails.name &&
      userDetails.role === "admin"
    ) {
      this.setState({
        name: userDetails.name,
        email: userDetails.email,
        role: userDetails.role
      });
    }
  }
  renderMainComponent = () => {
    if (!this.state.name) {
      return (
        <div className="Loderdiv">
          <Loader
            type="BallTriangle"
            color="#00BFFF"
            // height="100%"
            // width="100%"
            className="loader"
          />
        </div>
      );
    } else {
      return (
        <Grid item xs={false} sm={2} md={2} className="d-none d-sm-block">
          <div className="sidebar">
            <div
              className={
                this.state.role === "admin" ? "adminSideBar" : "mgabar"
              }
            >
              <div className="logoContainer">
                <img src={logo} alt="logo" />
              </div>

              <div className="UserDetailsContainer">
                <Typography
                  variant="subtitle1"
                  gutterBottom
                  className="secondarytext"
                >
                  Welcome
                </Typography>
                <Typography variant="h6" className="primarytext">
                  {this.state.name}
                </Typography>
                <Typography
                  variant="body2"
                  gutterBottom
                  className="steelGreytext"
                >
                  {this.state.email}
                </Typography>
              </div>

              {this.state.role === "distributor" ? (
                <div className="navigationControls ">
                  <Typography
                    variant="body1"
                    gutterBottom
                    className="steelGreytext"
                  >
                    <img
                      src={benifitSheild}
                      className="benefitsSheildImg"
                      alt="benefitsSheildImg"
                    />
                    BENEFITS
                  </Typography>
                  <Link to="/all-benefits">
                    <Typography
                      variant="body1"
                      gutterBottom
                      className={
                        this.state.activeTab === "benefits"
                          ? "steelGreytext submenu active"
                          : "steelGreytext submenu "
                      }
                    >
                      Activated Benefits
                    </Typography>
                  </Link>
                  <Link to="/pending-activation">
                    <Typography
                      variant="body1"
                      gutterBottom
                      className={
                        this.state.activeTab === "pending-activation"
                          ? "steelGreytext submenu active  "
                          : "steelGreytext submenu "
                      }
                    >
                      Non-Activated Benefits
                    </Typography>
                  </Link>
                </div>
              ) : (
                <div className="adminBenefit steelGreytext submenu_admin active adminNav">
                  <Link to="/all-benefits">
                    <img
                      src={blueBenefitSheild}
                      className="benefitsSheildImg"
                      alt="benefitsSheildImg"
                    />
                    BENEFITS
                  </Link>
                </div>
              )}
              {this.state.role === "distributor" ? (
                <div className="accountDetailsContainer">
                  <div>
                    <Typography
                      variant="caption"
                      gutterBottom
                      className="steelGreytext"
                    >
                      PAYOUT
                    </Typography>
                    <Typography
                      variant="h5"
                      gutterBottom
                      className="blacktext stats"
                    >
                      ${this.state.details.max_value}
                    </Typography>
                  </div>
                  <div>
                    <Typography
                      variant="caption"
                      gutterBottom
                      className="steelGreytext"
                    >
                      BENEFITS AVAILABLE
                    </Typography>
                    <Typography
                      variant="h5"
                      gutterBottom
                      className="blacktext stats"
                    >
                      {(
                        this.state.details.limit - this.state.details.issued
                      ).toString()}
                    </Typography>
                  </div>
                  <div>
                    <Typography
                      variant="caption"
                      gutterBottom
                      className="steelGreytext"
                    >
                      BENEFITS ACTIVATED
                    </Typography>
                    <Typography
                      variant="h5"
                      gutterBottom
                      className="blacktext stats"
                    >
                      {this.state.details.issued}
                    </Typography>
                  </div>
                </div>
              ) : (
                ""
              )}

              <div
                className={
                  this.state.role === "admin"
                    ? "accountSettingsController"
                    : "accountSettingsController"
                }
              >
                <Link to="/" onClick={() => userService.logout()}>
                  <Typography
                    variant="body1"
                    // gutterBottom
                    className="steelGreytext stats"
                  >
                    <img
                      src={account}
                      className="accountImage"
                      alt="accountImage"
                    />{" "}
                    LOG OUT
                  </Typography>
                </Link>
              </div>
            </div>

            <div className="poweredByContainer">
              <img src={powerebBy} alt="powered_by" />
            </div>
            <div className="borderCopy">
              <div className="copyRight" align="center">
                <p>© Machine Cover, Inc. 2019</p>
              </div>
            </div>
          </div>
        </Grid>
      );
    }
  };
  render() {
    return <React.Fragment>{this.renderMainComponent()}</React.Fragment>;
  }
}

export default MainComponent;

import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Toolbar from "@material-ui/core/Toolbar";
import AppBar from "@material-ui/core/AppBar";
import moment from "moment";

import downlaodImg from "../../assets/images/rawData/download_blue.svg";
import TableViewComponent from "./../common/tableView/table-component";

import { endPoints } from "./../../constants/end-point-url";
import { get } from "./../../helpers/axios";
import Loader from "../common/loader";
import "../main/main-component.css";
import "./raw-data-component.css";
import { Button } from "@material-ui/core";
import Filters from "./../common/filters.jsx";
// import { CSVLink } from "react-csv";

const content_Length = (
  <React.Fragment>
    <span>Content Length</span> <span id="small-font">(bytes)</span>
  </React.Fragment>
);
const Time_to_First_Byte = (
  <React.Fragment>
    <span>Time to First Byte</span> <span id="small-font">(ms)</span>
  </React.Fragment>
);
const tableHeadings = [
  { header: "Date", align: "left" },
  { header: "Time", align: "left" },
  { header: "Protocol", align: "left" },
  { header: "Scan Status", align: "right" },
  { header: content_Length, align: "right", key: "content_length" },
  { header: Time_to_First_Byte, align: "right", key: "time_to_first_byte" },
  //   { header: "URL", align: "left" },
  { header: "Exception", align: "left" }
];

class RawDataComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      csvLoader: false,
      csvCallcount: 0,
      tableHeadings: tableHeadings,
      rows: [],
      id: props.match.params.id,
      callCount: 0,
      limit: 20,
      urls: [],
      loading: false,
      start:
        props.match.params.start === undefined ? "" : props.match.params.start,
      end: props.match.params.end === undefined ? "" : props.match.params.end,
      defaultSelectedMonth: {
        key: moment().format("MMM"),
        value: moment().format("MMM")
      },
      csvLimit: 30000,
      filterMonthOptions: moment
        .monthsShort()
        .map(month => ({ key: month, value: month })),
      defaultSelectedYear: {
        key: moment().format("YYYY"),
        value: moment().format("YYYY")
      },
      filterYearOptions: this.getYearArray(),
      showFilter: props.match.params.start === undefined ? true : false, //Flag to denote whether the month year filter should be shown.
      loading_data: false, // Flag for denoting loading of raw data table
      cavData: ""
    };
    this.windowScroll = this.windowScroll.bind(this);
    this.filterList = this.filterList.bind(this);
    this.monthChangeFilter = this.monthChangeFilter.bind(this);
    this.yearChangeFilter = this.yearChangeFilter.bind(this);
    this.objectToCsv = this.objectToCsv.bind(this);
  }
  download_csv = (csv, filename) => {
    var csvFile;
    var downloadLink;
    csvFile = new Blob([csv], { type: "text/csv" });
    downloadLink = document.createElement("a");
    downloadLink.download = filename;
    downloadLink.href = window.URL.createObjectURL(csvFile);
    downloadLink.style.display = "none";
    document.body.appendChild(downloadLink);
    downloadLink.click();
  };

  getYearArray() {
    var currentYear = new Date().getFullYear(), years = [];
    
    var startYear = 2019;

    var yearArray = [];  
    
    while ( startYear <= currentYear ) {
        yearArray.push(startYear++);
    }

    return yearArray.map(year => ({ key: year, value: year }));
  }

  /**
   * Funtcion: monthChangeFilter
   * Desc: function to handle change in seleted month in filter dropdown.
   **/
  monthChangeFilter(filterBy) {
    this.setState({ defaultSelectedMonth: filterBy }, function() {
      this.filterList();
    });
  }
  /**
   * Funtcion: yearChangeFilter
   * Desc: function to handle change in seleted year in filter dropdown.
   **/
  yearChangeFilter(filterBy) {
    this.setState({ defaultSelectedYear: filterBy }, function() {
      this.filterList();
    });
  }
  /**
   * Funtcion: filterList
   * Desc: function to filter impairment list using the .
   **/
  filterList() {
    var this_ = this;
    this_.setState(
      {
        rows: [],
        callCount: 0
      },
      function() {
        this_.getData();
      }
    );
  }
  componentDidMount() {
    this.getData();
    // this.getCSVData();

    window.addEventListener("scroll", this.windowScroll);

    var this_ = this;
    get(endPoints.single_warranty + this.state.id, {})
      .then(function(response) {
        var data = response.data;
        this_.setState({
          urls: data.definition.urls,
          loading: true
        });
      })
      .catch(function(error) {
        console.log(error);
      });
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.windowScroll);
  }

  getMonth(date)
  {
    var monthArray = [ "Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec" ];

    return monthArray.indexOf(date);
  }

  getData() {
    if (this.state.loading_data === false) {
      var this_ = this;
      var rows = this.state.rows;

      var params =
        this.state.start === ""
          ? {
              limit: this.state.limit,
              skip: this.state.limit * this.state.callCount,
              start:  moment(
                new Date(parseInt(this.state.defaultSelectedYear.value),this.getMonth(this.state.defaultSelectedMonth.value))
              )
              .startOf("month")
              .unix(),
              end: moment(
                 new Date(parseInt(this.state.defaultSelectedYear.value),this.getMonth(this.state.defaultSelectedMonth.value))
                )
                .endOf("month")
                .unix()
            }
          : {
              limit: this.state.limit,
              skip: this.state.limit * this.state.callCount,
              start: this.state.start,
              end: this.state.end
            };

      this.setState({ loading_data: true }, function() {
        get(endPoints.scans.replace(":id", this.state.id), {
          params: params
        })
          .then(function(response) {
            response.data.data.map(function(value, index) {
              rows.push({
                rowColor: value.status_code !== 200 ? "#ff5367" : "black",
                data: [
                  moment(value.timestamp * 1000).format("DD-MM-YYYY"),
                  moment(value.timestamp * 1000).format("HH:mm"),
                  value.url ? value.url.split(":")[0] : " ",
                  value.status_code,
                  value.content_length,
                  value.time_to_first_byte,
                  // value.url,
                  value.exception !== "" ? value.exception : "-"
                ]
              });
              if (response.data.has_more === false) {
                this_.setState({ has_more: false });
              }
              return true;
            });

            this_.setState({
              rows: rows,
              callCount: this_.state.callCount + 1
            });
          })
          .finally(function() {
            this_.setState({ loading_data: false });
          })
          .catch(function(error) {
            console.log(error);
          });
      });
    }
  }
  objectToCsv(data) {
    const csvRows = [];
    const headers = Object.keys(data[0]);
    csvRows.push(headers.join(","));
    for (const row of data) {
      this.values = headers.map(header => {
        const escaped = ("" + row[header]).replace(/"/g, '\\"');
        return `"${escaped}"`;
      });
      csvRows.push(this.values.join(","));
    }
    return csvRows.join("\n");
  }
  getCSVData() {
    var this_ = this;
    // var rows = this.state.rows;
    var csvJsonData;
    var startmonth;
    var endmonth;
    if (this.state.start === "") {
      startmonth = moment(
        this.state.defaultSelectedMonth.value +
          " " +
          this.state.defaultSelectedYear.value
      )
        .startOf("month")
        .unix();
      endmonth = moment(
        this.state.defaultSelectedMonth.value +
          " " +
          this.state.defaultSelectedYear.value
      )
        .endOf("month")
        .unix();
    } else {
      startmonth = this.state.start;
      endmonth = this.state.end;
    }

    var Fileuri =
      "_" +
      moment(startmonth * 1000).format("DD MMMM YYYY") +
      "_" +
      moment(endmonth * 1000).format("DD MMMM YYYY");
    var filename =
      this.state.urls[0]
        .replace("https://", "")
        .replace("http://", "")
        .replace("/", "") +
      Fileuri +
      ".csv";

    var params =
      this.state.start === ""
        ? {
            limit: this.state.csvLimit,
            skip: this.state.csvLimit * this.state.csvCallcount,
            start: moment(
              this.state.defaultSelectedMonth.value +
                " " +
                this.state.defaultSelectedYear.value
            )
              .startOf("month")
              .unix(),
            end: moment(
              this.state.defaultSelectedMonth.value +
                " " +
                this.state.defaultSelectedYear.value
            )
              .endOf("month")
              .unix()
          }
        : {
            limit: this.state.limit,
            skip: 0,
            start: this.state.start,
            end: this.state.end
          };
    this_.setState({ csvLoader: true }, function() {
      get(endPoints.scans.replace(":id", this.state.id), {
        params: params
      })
        .then(function(response) {
          // console.log(response);
          csvJsonData = response.data.data.map(value => ({
            Date: moment(value.timestamp * 1000).format("DD-MM-YYYY"),
            Time: moment(value.timestamp * 1000).format("HH:mm"),
            Protocol: value.url ? value.url.split(":")[0] : " ",
            Scan_Status: value.status_code,
            Content_Length: value.content_length,
            Time_to_First: value.time_to_first_byte,
            Exception: value.exception !== "" ? value.exception : "-"
          }));
          if (response.data.has_more === false) {
            this_.setState({
              csvhas_more: response.data.has_more
            });
          }
          // console.log(csvJsonData);

          this_.setState({
            callCount: this_.state.callCount + 1
          });
        })
        .finally(function() {
          const finalCsvdata = this_.objectToCsv(csvJsonData);
          this_.download_csv(finalCsvdata, filename);
          this_.setState({ csvLoader: false });
        })

        .catch(function(error) {
          console.log(error);
        });
    });
  }

  windowScroll(e) {
    if (
      window.innerHeight + window.pageYOffset >= document.body.offsetHeight &&
      this.state.loading_data === false
    ) {
      this.getData();
    }
  }

  renderRawData = () => {
    var today = new Date();
    var time = today.getHours() + "." + today.getMinutes();
    var date =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();

    var uri = "_" + date + " " + time;
    if (this.state.loading === false || this.state.csvLoader) {
      return (
        <div className="Loderdiv">
          <Loader type="BallTriangle" color="#00BFFF" className="loader" />
        </div>
      );
    } else {
      return (
        <div className="mainDiv" onScroll={this.windowScroll}>
          <CssBaseline />
          <AppBar position="static" className="rawDataAppbarStyle">
            <Toolbar className="rawDataAppbarStyle">
              <Typography variant="h5" edge="start" className="rawDataHeader">
                RAW DATA
              </Typography>
              <div className="rightPaddingData">
                <img src={downlaodImg} alt="download" className="downloadImg" />
                <Button
                  className="primarytext"
                  disableRipple
                  onClick={() => this.getCSVData()}
                >
                  Download as CSV
                </Button>
                {/* <CSVLink data={this.finalCsvdata}>Download me</CSVLink>; */}
              </div>
              {this.state.showFilter && (
                <div className="filter-dropdown-container rightPaddingData">
                  <Filters
                    defaultSelected={this.state.defaultSelectedMonth}
                    options={this.state.filterMonthOptions}
                    isMonthYearFilter={true}
                    actionHandler={this.monthChangeFilter}
                  />
                </div>
              )}
              {this.state.showFilter && (
                <div className="filter-dropdown-container">
                  <Filters
                    defaultSelected={this.state.defaultSelectedYear}
                    options={this.state.filterYearOptions}
                    isMonthYearFilter={true}
                    actionHandler={this.yearChangeFilter}
                  />
                </div>
              )}
            </Toolbar>
          </AppBar>
          <div className="topDivInfo">
            <Grid container>
              <Grid
                item
                xs={6}
                sm={4}
                md={2}
                className="steelGreytext urlHeading"
              >
                URLS
              </Grid>
              <Grid item xs={6} sm={8} md={10} className="secondarytext url">
                {this.state.urls.map(function(value, index) {
                  return (
                    <div key={index}>
                      <a href={value}>{value}</a>
                    </div>
                  );
                })}

                <div>This are the original URLs being scanned</div>
              </Grid>
            </Grid>
            <Grid container>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                className="steelGreytext parameterHeading"
              >
                DESCRIPTION OF PARAMETERS
              </Grid>
            </Grid>
            <Grid container>
              <Grid
                item
                xs={6}
                sm={4}
                md={2}
                className="steelGreytext parameterHeading"
              >
                Date
              </Grid>
              <Grid
                item
                xs={6}
                sm={8}
                md={10}
                className="secondarytext parameter"
              >
                Date of scan
              </Grid>
            </Grid>
            <Grid container>
              <Grid
                item
                xs={6}
                sm={4}
                md={2}
                className="steelGreytext parameterHeading"
              >
                Time
              </Grid>
              <Grid
                item
                xs={6}
                sm={8}
                md={10}
                className="secondarytext parameter"
              >
                Approximate time of scan
              </Grid>
            </Grid>

            <Grid container>
              <Grid
                item
                xs={6}
                sm={4}
                md={2}
                className="steelGreytext parameterHeading"
              >
                Scan Status
              </Grid>
              <Grid
                item
                xs={6}
                sm={8}
                md={10}
                className="secondarytext parameter"
              >
                HTTP response status (This will be -1 in case of exception)
              </Grid>
            </Grid>
            <Grid container>
              <Grid
                item
                xs={6}
                sm={4}
                md={2}
                className="steelGreytext parameterHeading"
              >
                Content Length (bytes)
              </Grid>
              <Grid
                item
                xs={6}
                sm={8}
                md={10}
                className="secondarytext parameter"
              >
                Size of the page downloaded in bytes
              </Grid>
            </Grid>
            <Grid container>
              <Grid
                item
                xs={6}
                sm={4}
                md={2}
                className="steelGreytext parameterHeading"
              >
                Time to First Byte (ms)
              </Grid>
              <Grid
                item
                xs={6}
                sm={8}
                md={10}
                className="secondarytext parameter"
              >
                Time taken (in ms) to start receiving header data
              </Grid>
            </Grid>
            <Grid container>
              <Grid
                item
                xs={6}
                sm={4}
                md={2}
                className="steelGreytext parameterHeading"
              >
                Exception
              </Grid>
              <Grid
                item
                xs={6}
                sm={8}
                md={10}
                className="secondarytext parameter"
              >
                Exception message received in case the HTTP or HTTPS scan failed
              </Grid>
            </Grid>
          </div>

          <div className="tableContent">
            <TableViewComponent
              tableHeadings={this.state.tableHeadings}
              rowData={this.state.rows}
            />

            {this.state.loading_data === true &&
            this.state.has_more !== false ? (
              <React.Fragment>
                <br />
                <Loader
                  type="BallTriangle"
                  color="#00BFFF"
                  height="34px"
                  width="34px"
                />
              </React.Fragment>
            ) : null}
          </div>
        </div>
      );
    }
  };

  render() {
    return <React.Fragment>{this.renderRawData()}</React.Fragment>;
  }
}

export default RawDataComponent;

export const BenefitActions = {
	storeAllBenefitsList,
	storeBenefitDetails
}

function storeAllBenefitsList(list)
{
	return {type:'STORE_BENEFITS_LIST',list:list};
}

function storeBenefitDetails(details)
{
	return {type:'STORE_BENEFIT_DETAILS',details:details};
}
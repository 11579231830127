import React from "react";
import { Link as RouterLink } from "react-router-dom";
import Loader from "../common/loader";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Grid from "@material-ui/core/Grid";
import moment from "moment";
import * as jsPDF from "jspdf";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import downlaodImg from "../../assets/images/benefitDetails/download.svg";
import tableImg from "../../assets/images/benefitDetails/table.svg";
import fadeLogo from "../../assets/images/common/fade_logo.svg";

import "./impair-history.css";

import TableViewComponent from "../common/tableView/table-component";
import Filters from "./../common/filters.jsx";
import { endPoints } from "./../../constants/end-point-url";
import { get } from "./../../helpers/axios";
import { impaimentUiMap } from "./../../constants/impairment-state-constant";
import right from "./../../assets/images/impairmentTable/right.png";
import wrong from "./../../assets/images/impairmentTable/wrong.png";
import processing from "./../../assets/images/impairmentTable/processing.svg";
import { array } from "prop-types";
class ImpairmentHistoryComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      warrantyDetails: {},
      expandAllRows: false,
      id: props.id,
      limit: 20,
      rows: [],
      callCount: 0,
      hasMore: false,
      reachedBottom: props.reachedBottom,
      loading: false,
      defaultSelectedMonth: {
        key: moment().format("MMM"),
        value: moment().format("MMM")
      },
      filterMonthOptions: moment
        .monthsShort()
        .map(month => ({ key: month, value: month })),
      defaultSelectedYear: {
        key: moment().format("YYYY"),
        value: moment().format("YYYY")
      },
      filterYearOptions: this.getYearArray()
    };
    this.expandAllRow = this.expandAllRow.bind(this);

    this.tableHeadings = [
      { header: "Impairment Start", align: "left" },
      { header: "Impairment End", align: "left" },
      { header: "Hours", align: "left" },
      {
        header: (
          <Grid container>
            <Grid item xs={8} md={8} sm={8}>
              Summary
            </Grid>
            {/* <Grid
              item
              xs={4}
              md={4}
              sm={4}
              className="text-right smallText expandCollapse"
              onClick={this.expandAllRow}
            >
              {this.state.expandAllRows ? "Collapse" : "Expand"} all
            </Grid> */}
          </Grid>
        ),
        align: "left"
      }
    ];

    this.getTheNextRows = this.getTheNextRows.bind(this);
    this.generatePDF = this.generatePDF.bind(this);
    this.filterList = this.filterList.bind(this);
    this.monthChangeFilter = this.monthChangeFilter.bind(this);
    this.yearChangeFilter = this.yearChangeFilter.bind(this);
  }

  /** Function: getYearArray
      Desc: Function to get an array of years strating from 2019 to current year for year filter.
  **/
  getYearArray() {
    var currentYear = new Date().getFullYear(), years = [];
    
    var startYear = 2019;

    var yearArray = [];  
    
    while ( startYear <= currentYear ) {
        yearArray.push(startYear++);
    }

    return yearArray.map(year => ({ key: year, value: year }));
  }

  componentDidMount() {
    var this_ = this;
    this.fetchData(
      {
        limit: this.state.limit
      },
      function(response) {
        this_.formatRows(response);
      },
      function(error) {
        console.log(error);
      }
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.reachedBottom !== prevProps.reachedBottom) {
      var this_ = this;
      this.setState({ reachedBottom: this.props.reachedBottom }, function() {
        if (this_.state.hasMore) {
          this_.getTheNextRows();
        }
      });
    }
  }

  getMonth(date)
  {
    var monthArray = [ "Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec" ];

    return monthArray.indexOf(date);
  }

  fetchData(params, successsCb, failureCb) {
    var this_ = this;
    //Start and end timestamp to fetch the impairment history.
    
    var start = moment(
        new Date(parseInt(this.state.defaultSelectedYear.value),this.getMonth(this.state.defaultSelectedMonth.value))
      )
      .startOf("month")
      .unix();



    var end = moment(
       new Date(parseInt(this.state.defaultSelectedYear.value),this.getMonth(this.state.defaultSelectedMonth.value))
      )
      .endOf("month")
      .unix();

   
    get(
      endPoints.events.replace(":id", this.state.id) +
        "?start=" +
        start +
        "&end=" +
        end,
      {
        params
      }
    )
      .then(function(response) {
        successsCb(response);
      })
      .catch(function(error) {
        failureCb(error);
      });
  }

  getTheNextRows() {
    var this_ = this;

    if (!this.state.loading) {
      this.setState({ loading: true }, function() {
        this.fetchData(
          {
            limit: this.state.limit,
            skip: this.state.limit * this.state.callCount
          },
          function(response) {
            this_.formatRows(response);
          },
          function(error) {
            console.log(error);
          }
        );
      });
    }
  }

  formatRows(response) {
    var data = response.data;
    var rows = this.state.rows;
    var id = this.state.id;

    var arr = [];
    var selfCertification = false;
    data.data.map(function(value, index) {
      var triggered = false;

      var metaData = [];

      var ms = moment(value.end * 1000).diff(moment(value.start * 1000));
      var d = moment.duration(ms);

      var diff = Math.floor(d.asHours()) + moment.utc(ms).format(":mm:ss");
      var red = false;
      var prevCondition = {};
      metaData = value.metadata.map(function(value, index, prev) {
        prevCondition = prev[index - 1];
        if (value.condition === "TRIGGERED" && value.satisfied) {
          triggered = true;
        }

        arr.push(value);

        if (
          ((value.condition === "OUTAGE_IN_QUALIFICATON_PERIOD" &&
            value.satisfied === true) ||
            (value.condition === "INSUFFICIENT_QUALIFICATON_PERIOD" &&
              value.satisfied === true)) &&
          red === false
        ) {
          return (
            <div className="expandedDiv">
              <img
                src={wrong}
                alt="condition_satisfied"
                className="impairmentStateImg"
              />
              There was an outage within 24 hours prior to the start of this
              outage
              {(red = true)}
            </div>
          );
        }

        if (
          value.condition === "OUTAGE_IN_QUALIFICATON_PERIOD" &&
          value.satisfied === false &&
          (arr[2].condition === "INSUFFICIENT_QUALIFICATON_PERIOD" &&
            arr[2].satisfied === false) &&
          red === false
        ) {
          return (
            <div className="expandedDiv">
              <img
                src={right}
                alt="condition_satisfied"
                className="impairmentStateImg"
              />
              There was no outage within 24 hours prior to the start of this
              outage
            </div>
          );
        }
        if (
          value.condition === "BELOW_MIN_THRESHOLD" &&
          value.satisfied === true &&
          red === false
        ) {
          return (
            <div className="expandedDiv">
              <img
                src={wrong}
                alt="condition_satisfied"
                className="impairmentStateImg"
              />
              The outage was shorter than 24 hours
              {(red = true)}
            </div>
          );
        }
        if (
          value.condition === "ABOVE_MAX_THRESHOLD" &&
          value.satisfied === true &&
          red === false
        ) {
          return (
            <div className="expandedDiv">
              <img
                src={wrong}
                alt="condition_satisfied"
                className="impairmentStateImg"
              />
              The outage was longer 7 days
              {(red = true)}
            </div>
          );
        }

        if (
          value.condition === "ABOVE_MAX_THRESHOLD" &&
          value.satisfied === false &&
          (prevCondition.condition === "BELOW_MIN_THRESHOLD" &&
            prevCondition.satisfied === false) &&
          red === false
        ) {
          // debugger;
          // console.log(
          //   prevCondition.condition,
          //   "Satisfied",
          //   +prevCondition.satisfied
          // );
          return (
            <div className="expandedDiv">
              <img
                src={right}
                alt="condition_satisfied"
                className="impairmentStateImg"
              />
              The outage was longer than 24 hours and shorter than 7 days
            </div>
          );
        }

        if (
          value.condition === "AWAITING_RECOVERY_PERIOD" &&
          value.satisfied === true &&
          red === false
        ) {
          return (
            <div className="expandedDiv">
              <img
                src={processing}
                alt="condition_satisfied"
                className="impairmentStateImg"
              />
              Recovery period still ongoing. Trigger status will be updated once
              this period is over
              {(red = true)}
            </div>
          );
        }
        if (
          value.condition === "OUTAGE_IN_RECOVERY_PERIOD" &&
          value.satisfied === true &&
          red === false
        ) {
          return (
            <div className="expandedDiv">
              <img
                src={wrong}
                alt="condition_satisfied"
                className="impairmentStateImg"
              />
              There was an outage in recovery period
              {(red = true)}
            </div>
          );
        }
        if (arr[4]) {
          if (
            arr[4].condition === "AWAITING_RECOVERY_PERIOD" &&
            arr[4].satisfied === false &&
            (value.condition === "OUTAGE_IN_RECOVERY_PERIOD" &&
              value.satisfied === false) &&
            red === false
          ) {
            return (
              <div className="expandedDiv">
                <img
                  src={right}
                  alt="condition_satisfied"
                  className="impairmentStateImg"
                />
                There was no outage in recovery period
              </div>
            );
          }
        }
        if (
          value.condition === "MASS_OUTAGE" &&
          value.satisfied === true &&
          red === false
        ) {
          return (
            <div className="expandedDiv">
              <img
                src={wrong}
                alt="condition_satisfied"
                className="impairmentStateImg"
              />
              A mass outage was detected
              {(red = true)}
            </div>
          );
        }
        if (
          value.condition === "MASS_OUTAGE" &&
          value.satisfied === false &&
          red === false
        ) {
          return (
            <div className="expandedDiv">
              <img
                src={right}
                alt="condition_satisfied"
                className="impairmentStateImg"
              />
              No mass outage was detected
            </div>
          );
        }
        if (
          value.condition === "NO_SELF_CERT" &&
          value.satisfied === true &&
          red === false
        ) {
          return (
            <div className="expandedDiv">
              <img
                src={wrong}
                alt="condition_satisfied"
                className="impairmentStateImg"
              />
              The owner has not self-certified the outage as valid
              {(red = true)}
            </div>
          );
        }
        if (
          value.condition === "AWAITING_SELF_CERT" &&
          value.satisfied === true &&
          red === false
        ) {
          return (
            <div className="expandedDiv">
              <img
                src={processing}
                alt="condition_satisfied"
                className="impairmentStateImg"
              />
              Awaiting self-certification
              {(red = true)}
            </div>
          );
        }

        if (arr[7]) {
          if (
            value.condition === "AWAITING_SELF_CERT" &&
            value.satisfied === false &&
            (arr[7].condition === "NO_SELF_CERT" && arr[7].satisfied === false)
          ) {
            selfCertification = true;
            // return (
            //   <div className="expandedDiv">
            //     <img
            //       src={right}
            //       alt="condition_satisfied"
            //       className="impairmentStateImg"
            //     />
            //     The owner has self-certified the outage as valid
            //   </div>
            // );
          }
        }

        // return (
        //   <div className="expandedDiv">
        //     <img
        //       src={value.satisfied ? right : wrong}
        //       alt="condition_satisfied"
        //       className="impairmentStateImg"
        //     />
        //     {impaimentUiMap[value.condition]}
        //   </div>
        // );
      });
      var summary =
        triggered && selfCertification === true ? (
          <Grid container>
            <Grid item xs={8} md={9} sm={9} className="outrageStatus ">
              Website{" "}
              <a href={value.url} className="triggerLink">
                {value.url}
              </a>{" "}
              was down and the{" "}
              <span className="triggerdSpan">
                benefit triggered subject to self-certification
              </span>
            </Grid>

            <Grid
              item
              xs={4}
              md={3}
              sm={3}
              className="text-right primarytext smallText"
            >
              <RouterLink
                to={"/raw-data/" + id + "/" + value.start + "/" + value.end}
                target="_blank"
              >
                <span className="view_raw_data">View raw data</span>
              </RouterLink>
              <ExpandMoreIcon className="expandMoreIcon" />
            </Grid>
          </Grid>
        ) : (
          <Grid container>
            <Grid item xs={8} md={9} sm={9} className="outrageStatus">
              Website{" "}
              <a href={value.url} className="triggerLink">
                {value.url}
              </a>{" "}
              was down but the benefit did not trigger
            </Grid>

            <Grid
              item
              xs={4}
              md={3}
              sm={3}
              className="text-right primarytext smallText"
            >
              <RouterLink
                to={"/raw-data/" + id + "/" + value.start + "/" + value.end}
                target="_blank"
              >
                <span className="view_raw_data">View raw data</span>
              </RouterLink>
              <ExpandMoreIcon className="expandMoreIcon" />
            </Grid>
          </Grid>
        );
      rows.push({
        rowColor: "black",
        data: [
          moment(value.start * 1000).format("DD MMMM YYYY h:mm A"),
          moment(value.end * 1000).format("DD MMMM YYYY h:mm A"),
          diff,
          [summary, metaData]
        ]
      });

      return true;
    });

    this.setState({
      rows: rows,
      callCount: this.state.callCount + 1,
      hasMore: response.data.has_more,
      loading: false
    });
  }

  expandAllRow() {
    this.setState({ expandAllRows: !this.state.expandAllRows });
  }

  generatePDF() {
    // this.setState({ expandAllRow: false }, function() {
    //   var pdf = new jsPDF("p", "pt", "a2");
    //   pdf.setFontSize(18);
    //   pdf.fromHTML(document.getElementById("table"));
    //   pdf.save("impairement.pdf");
    // });
  }

  /**
   * Funtcion: monthChangeFilter
   * Desc: function to handle change in seleted month in filter dropdown.
   **/
  monthChangeFilter(filterBy) {
    this.setState(
      { defaultSelectedMonth: filterBy, loading: true, callCount: 0 },
      function() {
        this.filterList();
      }
    );
  }
  /**
   * Funtcion: yearChangeFilter
   * Desc: function to handle change in seleted year in filter dropdown.
   **/
  yearChangeFilter(filterBy) {
    this.setState(
      { defaultSelectedYear: filterBy, loading: true, callCount: 0 },
      function() {
        this.filterList();
      }
    );
  }

  /**
   * Funtcion: filterList
   * Desc: function to filter impairment list using the .
   **/
  filterList() {
    var this_ = this;
    this.fetchData(
      {
        limit: this.state.limit
      },
      function(response) {
        this_.setState({ rows: [] });
        this_.formatRows(response);
      },
      function(error) {
        console.log(error);
      }
    );
  }

  render() {
    return (
      <div className="impairHeader">
        <div className="impairpaper">
          <div className="headerRow">
            <AppBar position="static" className="impairAppbarStyle">
              <Toolbar className="impairAppbarStyle">
                <Typography variant="h6" edge="start" className="impHistHeader">
                  Impairment History
                </Typography>
                <RouterLink
                  to={"/raw-data/" + this.state.id}
                  className="rightPaddingData"
                  target="_blank"
                >
                  <img src={tableImg} className="tableImg" alt="back" />
                  <span className="ViewDataLink">View all raw data</span>
                </RouterLink>
                {/* <div onClick={this.generatePDF} className="downloadPDF rightPaddingData">
                  <img src={downlaodImg} className="tableImg" alt="back" />
                  <span className="ViewDataLink">Download as PDF</span>
                </div> */}
                <div className="filter-dropdown-container rightPaddingData">
                  <Filters
                    defaultSelected={this.state.defaultSelectedMonth}
                    options={this.state.filterMonthOptions}
                    isMonthYearFilter={true}
                    actionHandler={this.monthChangeFilter}
                  />
                </div>

                <div className="filter-dropdown-container">
                  <Filters
                    defaultSelected={this.state.defaultSelectedYear}
                    options={this.state.filterYearOptions}
                    isMonthYearFilter={true}
                    actionHandler={this.yearChangeFilter}
                  />
                </div>
              </Toolbar>
            </AppBar>
          </div>
        </div>
        {!!this.state.rows.length ? (
          <div className="tableCompView" id="table">
            <TableViewComponent
              tableHeadings={this.tableHeadings}
              rowData={this.state.rows}
              rowExpanded={this.state.expandAllRows}
              expandAllRow={this.expandAllRows}
            />
            {this.state.loading === true ? (
              <React.Fragment>
                <br />
                <Loader
                  type="BallTriangle"
                  color="#00BFFF"
                  height="34px"
                  width="34px"
                />
                <br />
              </React.Fragment>
            ) : (
              ""
            )}
          </div>
        ) : (
          <div className="noDataMessage">
            <img src={fadeLogo} alt="logo" className="noDataImg"></img>
            <p className="noDataMessageLbl">No impairments found</p>
          </div>
        )}
      </div>
    );
  }
}

export default ImpairmentHistoryComponent;

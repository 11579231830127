export const benefit_status_constant = [
  { key: "", value: "Show All" },
  { key: "ACTIVE", value: "Active" },
  {
    key: "TRIGGERED_AWAITING_CERTIFICATION",
    value: "Triggered"
  },
  { key: "TRIGGERED_READY_FOR_PAYOUT", value: "Ready for Payout" },
  { key: "PAID", value: "Paid" }
  // { key: "TERMINATED_BY_DISTRIBUTOR", value: "Terminated" }
  // { key: "TERMINATED_ON_EXPIRY", value: "Terminated" }
];

export const FilterOptions = [
  { key: "", value: "Show All" },
  { key: "ACTIVE", value: "Active" },
  {
    key: "TRIGGERED_AWAITING_CERTIFICATION",
    value: "Pending Self Cert."
  },
  { key: "TRIGGERED_READY_FOR_PAYOUT", value: "Ready for Payout" },
  { key: "PAID", value: "Paid" }
  // { key: "TERMINATED_ON_EXPIRY,TERMINATED_BY_DISTRIBUTOR", value: "Terminated" }
];

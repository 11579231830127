import { combineReducers } from "redux";

import { authentication } from "./authenticate-reducer";
import { alert } from "./alert-reducer";
import { benefitsList } from "./benefitReducer";
import { benefitsDetails } from "./benefit-details-Reducer";
import errorReducer from "./errorReducer";

const rootReducer = combineReducers({
  authentication,
  alert,
  benefitsList,
  benefitsDetails,
  errorReducer
});

export default rootReducer;

import React from "react";
import { Link as RouterLink } from "react-router-dom";

import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";

import backArr from "../../assets/images/loginScreen/back_small.svg";
import "./contactInfo.css";

class ContactInfoComponent extends React.Component {
  render() {
    return (
      <Grid container component="main" className="root">
        <CssBaseline />
        <Grid item xs={false} sm={3} md={4} className="image" />
        <Grid item xs={12} sm={9} md={8} component={Paper} elevation={6} square>
          <div className="paper">
            <div className="headerRow">
              <AppBar position="static" className="appbarStyle">
                <Toolbar className="appbarStyle">
                  <Typography variant="h6" className="header" />
                  <Button color="inherit" className="headerText">
                    ABOUT{" "}
                  </Button>
                  <RouterLink to={"/contact"}>
                    <Button color="inherit" className="headerText contactText">
                      CONTACT US
                    </Button>
                  </RouterLink>
                </Toolbar>
              </AppBar>
            </div>
            <div className="formDiv">
              <h3 className="formHeader">Contact Us</h3>
              <div className="contentDiv">
                <p className="contactInfo">
                  Have questions about the WID benefit?
                </p>
                <p className="contactInfo">
                  Please email your queries to
                  <a
                    className="linkName underline"
                    href="mailto:support@machinecover.com"
                  >
                    {" "}
                    support@machinecover.com{" "}
                  </a>
                  and we’ll respond within 1 business day.
                </p>
                <p className="backToLoginPara">
                  <RouterLink to={"/login"}>
                    <img src={backArr} className="backImg" alt="back" />
                    <span className="backToLogin">BACK TO LOGIN</span>
                  </RouterLink>
                </p>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    );
  }
}

export default ContactInfoComponent;

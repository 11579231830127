import { userConstants } from "../constants/user-action-constant";

const initialState = {};

export function authentication(state = initialState, action) {
  switch (action.type) {
    case userConstants.LOGIN_SUCCESS:
      return Object.assign({}, state, {
        loggedIn: true,
        user: action.user
      });
    case userConstants.LOGIN_FAILURE:
      return Object.assign({}, state, {});
    case userConstants.LOGOUT:
      return Object.assign({}, state, {});
    default:
      return state;
  }
}

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import { store } from './helpers/store';
import './font.css';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
// setup fake backend
import { configureFakeBackend } from './components/common/delete';
configureFakeBackend();

ReactDOM.render(
    <Provider  store={store}>
     <App />
    </Provider>,
    document.getElementById('app')
   );
serviceWorker.register();
import { userConstants } from "../constants/user-action-constant";

export const userActions = {
  success,
  logout,
  failure
};

function logout() {
  return { type: userConstants.LOGOUT };
}
function success(user) {
  return { type: userConstants.LOGIN_SUCCESS, user };
}
function failure(error) {
  return { type: userConstants.LOGIN_FAILURE, error };
}

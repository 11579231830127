import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import "./table-view.css";

class TableViewComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tableHeadings: props.tableHeadings,
      rowsData: props.rowData,
      rowExpanded: props.rowExpanded === undefined ? false : props.rowExpanded,
      expandAllRow: props.expandAllRow
    };

    this.expandAllRow = this.expandAllRow.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.rowExpanded !== prevProps.rowExpanded) {
      this.setState({
        rowExpanded: this.props.rowExpanded,
        tableHeadings: this.props.tableHeadings
      });
    }

    if (this.props.rowData !== prevProps.rowData) {
      this.setState({ rowsData: this.props.rowData });
    }
  }

  expandAllRow() {
    this.state.expandAllRow();
  }

  buildDataRows() {
    //var state = this.state.rowExpanded;
    var builtDataRows = this.state.rowsData.map(function(row, key) {
      return (
        <TableRow key={key} className="table-row">
          {row.data.map(function(item, key) {
            return (
              <TableCell
                className="tableValText"
                key={key}
                // align={typeof item === "number" ? "right" : "left"}
                size="medium"
              >
                {typeof item === "object" ? (
                  <ExpansionPanel
                    className="expPanel"
                    TransitionProps={{ unmountOnExit: true }}
                  >
                    <ExpansionPanelSummary
                      // expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      className="expSummary "
                    >
                      {item[0]}
                    </ExpansionPanelSummary>
                    {/* {console.log(item[1])} */}
                    {item[1].map(function(i, k) {
                      return i === undefined ? (
                        ""
                      ) : (
                        <ExpansionPanelDetails
                          className="expPanel expSummary"
                          key={k}
                        >
                          <div style={{ color: row.rowColor }}>{i}</div>
                        </ExpansionPanelDetails>
                      );
                    })}
                  </ExpansionPanel>
                ) : typeof item === "string" &&
                  (item === "http" || item === "https") ? (
                  <span
                    className={
                      item === "http" ? "protocolTag-http" : "protocolTag-https"
                    }
                  >
                    {item.toUpperCase()}
                  </span>
                ) : (
                  <span className="tableDiv" style={{ color: row.rowColor }}>
                    {item}
                  </span>
                )}
              </TableCell>
            );
          })}
        </TableRow>
      );
    });
    return builtDataRows;
  }

  render() {
    return (
      <Paper className="tableMain">
        <Table className="table">
          <TableHead className="tableHeader">
            <TableRow>
              {this.state.tableHeadings.map(function(item, key) {
                return (
                  <TableCell
                    key={key}
                    className={
                      item.header === "Impairment Start" ||
                      item.header === "Impairment End" ||
                      item.key === "content_length" ||
                      item.key === "time_to_first_byte"
                        ? "tableHeader_first"
                        : item.header === "Hours" ||
                          item.header === "Date" ||
                          item.header === "Protocol" ||
                          item.header === "Time" ||
                          item.header === "Scan Status"
                        ? "heading_width"
                        : "tableHeaderText"
                    }
                    // align={item.align}
                  >
                    {item.header}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>{this.buildDataRows()}</TableBody>
        </Table>
      </Paper>
    );
  }
}

export default TableViewComponent;

import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import fadeLogo from "../../assets/images/common/fade_logo.svg";
import MainComponent from "./../main/main-component.jsx";
import { BenefitActions } from "./../../actions/benefits-Action.js";
import { store } from "./../../helpers/store";
import CollapsibleTableRowComponent from "./../collapsibleTableRow/collapsibleTableRow-component";
import TableHeaderComponent from "./../tableHeader/table-header-component";
import { get } from "./../../helpers/axios";
import Loader from "../common/loader";

//css
import "./benefits-component.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

//constants
import { endPoints } from "./../../constants/end-point-url";
import {
  benefit_status_constant,
  FilterOptions
} from "./../../constants/benefit-status-constant.js";
import { screenHeadings } from "./../../constants/table-heading-constants";

class BenefitsComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      status: benefit_status_constant,
      list: [],
      filterBy: benefit_status_constant[0],
      sortBy_param: "benefit_id",
      sortDir: "desc",
      search: "",
      role: "distributor",
      limit: 12,
      callcount: 0,
      listLoader: false,
      ifBottomReached: false,
      loading: false,
      scroll: false,
      has_more: true,
      states: "Show All",
      loading_data: false // Flag for denoting loading of raw data table
    };

    this.initialState = this.state;

    this.filterOption = FilterOptions;
    this.filterBenefitsList = this.filterBenefitsList.bind(this);
    this.sortBenefitsList = this.sortBenefitsList.bind(this);
    this.searchText = this.searchText.bind(this);
    this.clearSearchText = this.clearSearchText.bind(this);
    this.windowScroll = this.windowScroll.bind(this);
  }

  componentDidMount() {
    this.setState({ listLoader: true });
    this.hitApi({
      sort_by: this.state.sortBy_param,
      sort_dir: this.state.sortDir
    });
  }
  componentWillUnmount() {
    this.props.history.goForward();
  }
  componentDidUpdate() {
    // var userDetails = store.getState().authentication.user;
    // if (userDetails.role !== this.state.role) {
    //   this.setState({ role: userDetails.role });
    // }
  }

  filterBenefitsList(filterBy) {
    this.clearSearchText();
    if (this.state.filterBy) {
      this.setState({
        eligible: "",
        ifBottomReached: false,
        scroll: false,
        callcount: 0
      });
    }

    this.setState(
      {
        loading: true,

        filterLoader: true,
        search: ""
      },

      function() {
        if (filterBy.key !== "") {
          this.setState({
            filterBy: filterBy,
            filter: true,
            states: filterBy.key
          });
          this.hitApi({ states: filterBy.key });
        } else {
          this.setState({
            filter: true,
            filterBy: { key: "", value: "Show All" },
            states: filterBy.value
          });
          this.hitApi({});
        }
      }
    );
  }

  sortBenefitsList(parameter, sortDir) {
    var this_ = this;

    if (this.state.states === "Show All") {
      this.setState(
        {
          sortBy_param: parameter,
          sortDir: sortDir,
          loading: true,
          sortData: true,
          sortLoader: true,
          callcount: 0
        },
        function() {
          this_.hitApi({
            sort_by: parameter,
            sort_dir: this_.state.sortDir
          });
        }
      );
    } else {
      this.setState(
        {
          sortBy_param: parameter,
          sortDir: sortDir,
          loading: true,
          sortData: true,
          sortLoader: true,
          callcount: 0
        },
        function() {
          this_.hitApi({
            sort_by: parameter,
            sort_dir: this_.state.sortDir,
            states: this.state.states
          });
        }
      );
    }
  }

  searchText(searchText) {
    this.setState(
      {
        sortBy_param: this.initialState.sortBy_param,
        sortDir: this.initialState.sortDir,
        search: searchText,
        filterBy: this.initialState.filterBy,
        ifBottomReached: false,
        loading: true
      },
      function() {
        this.hitApi({ q: searchText });
      }
    );
  }

  //Function: clearSearchText
  //Desc: Function to clear the search textfield.
  clearSearchText() {
    this.setState({
      search: ""
    });
    this.refs.tableHeader.clearSearchText();
  }

  hitApi(params) {
    if (this.state.loading_data === false) {
      var this_ = this;
      params.limit = this.state.limit;
      var callcount = this.state.callcount;

      if (
        this.state.filter &&
        this.state.ifBottomReached &&
        this.state.sortData !== true
      ) {
        if (this.state.filterBy.key === "") {
          params.skip = this.state.limit * (callcount + 1);

          this.setState({
            callcount: callcount + 1,
            scroll: true
          });
        } else {
          params = { states: this.state.filterBy.key };
          params.skip = this.state.limit * (callcount + 1);

          this.setState({
            callcount: callcount + 1,
            scroll: true
          });
        }
      }

      if (this.state.search !== "" && this.state.ifBottomReached) {
        params = { q: this.state.search };
        params.skip = this.state.limit * (callcount + 1);

        this.setState({
          callcount: callcount + 1,
          scroll: true
        });
      }

      if (this.state.sortData && this.state.ifBottomReached) {
        if (this.state.states === "Show All") {
          params = {
            sort_by: this.state.sortBy_param,
            sort_dir: this.state.sortDir
          };
          params.skip = this.state.limit * (callcount + 1);

          this.setState({
            callcount: callcount + 1,
            scroll: true
          });
        } else {
          params = {
            sort_by: this.state.sortBy_param,
            sort_dir: this.state.sortDir,
            states: this.state.filterBy.key
          };
          params.skip = this.state.limit * (callcount + 1);

          this.setState({
            callcount: callcount + 1,
            scroll: true
          });
        }
      } else if (
        this.state.ifBottomReached &&
        this.state.filter !== true &&
        this.state.sortData !== true
      ) {
        params.skip = this.state.limit * (callcount + 1);

        this.setState({
          callcount: callcount + 1,
          scroll: true
        });
      }
      this_.setState({ loading_data: true }, function() {
        get(endPoints.all_warranties, {
          params: params
        })
          .then(function(response) {
            var list = this_.state.list;
            if (callcount !== this_.state.callcount) {
              response.data.data = list.concat(response.data.data);
              // if (response.data.has_more === false) {
              //   this_.setState({ has_more: false });
              // }
              this_.setState({ has_more: response.data.has_more });
            }
            store.dispatch(BenefitActions.storeAllBenefitsList(response.data));
          })
          .catch(function(error) {
            console.log(error);
          })
          .finally(function() {
            var benefits_list = store.getState();
            this_.setState({
              list: benefits_list.benefitsList.list,
              loading: false,
              listLoader: false,
              filterLoader: false,
              sortLoader: false,
              loading_data: false
            });
          });
      });
    }
  }

  windowScroll(e) {
    var target = e.target;

    if (
      target.scrollTop + target.offsetHeight >= target.scrollHeight &&
      this.state.loading_data === false
    ) {
      var this_ = this;

      this.setState({ ifBottomReached: true, scroll: true }, function() {
        this_.hitApi({});
      });
    } else {
      this.setState({ ifBottomReached: false, scroll: false });
    }
  }

  render() {
    return (
      <Grid container component="main" className="root">
        <CssBaseline />

        <MainComponent activeTab="benefits" />

        <Grid
          item
          xs={false}
          sm={10}
          md={10}
          className="all-benefits-container"
        >
          <TableHeaderComponent
            searchHandler={this.searchText}
            sortHandler={this.sortBenefitsList}
            filterHandler={this.filterBenefitsList}
            sortDir={this.state.sortDir}
            sortBy_param={this.state.sortBy_param}
            filterOptions={this.filterOption}
            defaultFilter={this.state.filterBy}
            screenHeadings={screenHeadings[this.state.role]}
            ref="tableHeader"
            // generatePDF={this.generatePDF}
          />

          <div className="benefitsContainer">
            {this.state.filterLoader === true ||
            this.state.sortLoader === true ||
            this.state.loading === true ||
            (this.state.listLoader && this.state.scroll === false) ? (
              <Loader
                height="34px"
                width="34px"
                background="#EFF3F7"
                marginTop="40px"
              />
            ) : (
              ""
            )}
            {!!this.state.list.length ? (
              <Grid
                item
                xs={false}
                sm={12}
                md={12}
                className="tableRowsContainer"
                onScroll={this.windowScroll}
              >
                {this.state.list.map((item, key) => (
                  <CollapsibleTableRowComponent
                    key={key}
                    item={item}
                    url={item.definition.urls[0]}
                    status={this.state.status}
                    type={screenHeadings[[this.state.role]].type}
                  />
                ))}
                {this.state.loading_data === true &&
                this.state.has_more !== false &&
                this.state.loading === false ? (
                  <Loader
                    type="BallTriangle"
                    color="#00BFFF"
                    height="34px"
                    width="34px"
                  />
                ) : (
                  ""
                )}
              </Grid>
            ) : (
              <div className="tableRowsContainer">
                <div className="noDataMessage">
                  <img src={fadeLogo} alt="logo" className="noDataImg"></img>
                  <p className="noDataMessageLbl">No matching benefits found</p>
                </div>
              </div>
            )}
          </div>
        </Grid>
      </Grid>
    );
  }
}

export default BenefitsComponent;

import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import isEmail from "validator/lib/isEmail";
import TextField from "@material-ui/core/TextField";
import { userService } from "./../../services/login-service";
import { history } from "./../../helpers/history";

import "./login.css";

class LoginComponent extends React.Component {
  constructor(props) {
    super(props);


    // userService.logout();

    this.state = {
      emailId: "",
      password: "",
      submitted: false,
      loading: false,
      error: "",
      hidden: true,

      toggleText: "Show"
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.toggleShow = this.toggleShow.bind(this);
    userService.loginUser = userService.loginUser.bind(this);

    if(localStorage.getItem("token")){
      history.push("all-benefits");
    }
  }

  loadContactPage() {
    this.props.history.push("/contact");
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
    this.setState({ invalid: false });
  }

  handlePasswordChange(e) {
    this.setState({ password: e.target.value });
  }
  aboutClick = () => {
    window.location.assign("https://www.machinecover.com");
  };
  toggleShow() {
    if (this.state.email || this.state.password) {
      this.setState({ hidden: !this.state.hidden });
      if (this.state.toggleText === "Show") {
        this.setState({ toggleText: "Hide" });
      } else if (this.state.toggleText === "Hide") {
        this.setState({ toggleText: "Show" });
      }
    }
  }

  handleSubmit(e) {
    e.preventDefault();
    var email;
    this.setState({ submitted: true });
    const { emailId, password } = this.state;
    if (isEmail(emailId)) {
      email = emailId;
      this.setState({ loading: true });
      const _this = this;
      userService.loginUser(email, password, err => {
        if (err !== null) {
          _this.setState({ err: true });
        }
      });
    } else {
      this.setState({ emailId: emailId, invalid: true });
    }
    // stop here if form is invalid
    if (!(emailId && password)) {
      return;
    }
  }

  render() {
    const { emailId, password, submitted } = this.state;

    if (this.state.emailId !== "" && this.state.password !== "") {
      this.color = "#2491e0";
    } else {
      this.color = "#c9e1f1";
    }
    return (
      <Grid container component="main" className="rootLogin">
        <CssBaseline />
        <Grid item xs={false} sm={3} md={4} className="image" />
        <Grid item xs={12} sm={9} md={8} component={Paper} elevation={6} square>
          <div className="paper">
            <div className="headerRow">
              <AppBar position="static" className="appbarStyle">
                <Toolbar className="appbarStyle">
                  <Typography variant="h6" className="header" />
                  <Button
                    color="inherit"
                    className="headerText"
                    onClick={this.aboutClick}
                  >
                    ABOUT
                  </Button>
                  <Link to={"/contact"}>
                    <Button color="inherit" className="headerText">
                      CONTACT US
                    </Button>
                  </Link>
                </Toolbar>
              </AppBar>
            </div>
            <div className="formDiv">
              <h3 className="formHeader">Carrier and MGA Portal</h3>
              <form
                className="form"
                name="form"
                onSubmit={this.handleSubmit}
                noValidate
              >
                <div
                  className={
                    "form-group" + (submitted && !emailId ? " has-error" : "")
                  }
                >
                  <label htmlFor="emailId" className="inputlabel">
                    Email
                  </label>
                  <input
                    type="email"
                    className={
                      this.state.invalid === true
                        ? "form-control invalid"
                        : "form-control"
                    }
                    name="emailId"
                    placeholder="Enter Email"
                    value={this.state.emailId}
                    onChange={this.handleChange}
                  />
                  {submitted && this.state.invalid === true && (
                    <div className="help-block">Please enter valid email</div>
                  )}
                </div>
                <div
                  className={
                    "form-group" + (submitted && !password ? " has-error" : "")
                  }
                >
                  <Grid container>
                    <Grid item xs={6} sm={6} md={6}>
                      <label htmlFor="password" className="inputlabel">
                        Password
                      </label>
                    </Grid>
                    {/* <Grid item xs={6} sm={6} md={6} className="text-right">
                      <label
                        htmlFor="password"
                        className="forgotPasswordLabel secondarytext"
                      >
                        Forgot your password?
                      </label>
                    </Grid> */}
                  </Grid>
                  <input
                    className="form-control"
                    placeholder="Enter Password"
                    type={this.state.hidden ? "password" : "text"}
                    value={this.state.password}
                    onChange={this.handlePasswordChange}
                  />
                  {submitted && !password && (
                    <div className="help-block">Password is required</div>
                  )}
                  {this.state.err === true ? (
                    <div className="help-block">
                      Username/Password is incorrect
                    </div>
                  ) : (
                    ""
                  )}

                  <span
                    onClick={this.toggleShow}
                    className="hideShowBtn"
                    style={{ color: this.color }}
                  >
                    {this.state.toggleText}
                  </span>
                </div>
                <div className="form-group">
                  <Button
                    type="submit"
                    variant="contained"
                    className={"submit"}
                    disableRipple
                    disabled={
                      this.state.email === "" || this.state.password === ""
                        ? true
                        : false
                    }
                    color={
                      this.state.email === "" || this.state.password === ""
                        ? ""
                        : "primary"
                    }
                  >
                    Login
                  </Button>
                </div>
              </form>
            </div>
            <div className="footerRow d-none d-sm-block">
              <h5 className="footerText">
                Machine Cover welcomes all Insurance Carriers and Managing
                General Agents (MGAs) to distribute the WID benefit. If you
                would like to be a part of this program, please email{" "}
                <a className="linkName" href="mailto:info@machinecover.com">
                  info@machinecover.com
                </a>
              </h5>
            </div>
          </div>
        </Grid>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  const { loggingIn } = state.authentication;

  return {
    loggingIn
  };
}
export default connect(mapStateToProps)(LoginComponent);

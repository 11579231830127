export function benefitsList(state = {}, action){

	switch(action.type)
	{
		case 'STORE_BENEFITS_LIST': {
			return Object.assign({}, state, {"list":action.list.data});
		}

		default:{
			return state;
		}
	}
} 